import {
  TextField,
  Paper,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  Button,
  IconButton,
  InputLabel,
  Alert,
} from "@mui/material";
import { theme } from "../../../theme";
import { useEffect, useRef, useState } from "react";
import CountrySelect from "../../autocomplete/countries/CountriesComponent";
import { locacionesService } from "../../../services/locaciones";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import authService from "../../../services/auth";
import { LoadState } from "../../../enums/LoadStateEnum";
import {
  DOCUMENT,
  Errors,
  IGLESIA,
  IGLESIA_CAPITALIZED,
  Locaciones,
  Locations,
  RoleFormEnum,
  Sizes,
  CONTAINED,
  InOrder,
  FormTextSend,
  GUARDAR,
  UserRoles,
} from "../../../enums/components/componentsEnum";
import { styles } from "./AddRoleFormStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationSelect from "../../autocomplete/countries/LocationSelect";
import { SelectChangeEvent } from "@mui/material/Select";
import { initializeRolesIglesia } from "../../../utils/forms/formsUtils";
import { RoleIglesia } from "../../../interfaces/components/interfacesForms";

const AddRoleForm = ({
  title,
  errors,
  inGeneratedTurn = true,
  customStyle,
}: any) => {
  const [provinces, setProvinces] = useState<any[]>([]);
  const [parts, setParts] = useState<any[]>([]);
  const [rolesIglesia, setRolesIglesia] = useState<RoleIglesia[]>(
    initializeRolesIglesia,
  );
  const [iglesia, setIglesia] = useState(null);
  const [iglesias, setIglesias] = useState<any[]>([]);
  const loadState = useRef(LoadState.DEFAULT);
  const { id } = useParams();
  const navigate = useNavigate();
  const [roleSelected, setRoleSelected] = useState<any>([
    { id: 1, name: UserRoles.SACERDOTE },
    { id: 2, name: UserRoles.ADMINISTRATOR },
    { id: 3, name: UserRoles.SECRETARIA },
  ]);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const {
    data: countries,
    isLoading: locacionesDataIsLoading,
    error: locacionesDataError,
  } = useQuery<any | null>([Locaciones.LOCACIONES], () =>
    locacionesService.getCountries(),
  );

  const onChangeRol = (event: SelectChangeEvent<unknown>) => {
    const selectedRoleName = event.target.value as string;
    setRoleSelected([selectedRoleName]);
  };

  const handleCountryChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findProvincesByCountryId(value.id)
      .then((response: any) => {
        setProvinces(response);
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PROVINCES, error);
      });
  };

  const handleProvinceChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findPartsByProvinceId(value.id)
      .then((response: any) => {
        setParts(response);
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PARTS, error);
      });
  };

  const handlePartChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }

    locacionesService
      .findPartWithLocalities(value.id)
      .then((response: any) => {
        setIglesias(
          response.localities.flatMap((locality: any) => {
            const localityName = locality.name;
            return locality.iglesias.map((iglesia: any) => {
              iglesia.name = `${iglesia.denomination}-(${localityName})`;
              return iglesia;
            });
          }),
        );
      })
      .catch((error: any) => {
        console.log(Errors.ERROR, error);
      });
  };

  const handleSubmmit = async () => {
    const iglesiaData = {
      id,
      //@ts-ignore
      idIgle: iglesia.id,
      rolesIglesia: roleSelected,
    };

    await authService
      .addIglesiaToUser(iglesiaData)
      .then(() => {
        setMessage("Se agrego el rol correctamente");
        setShowAlert(true);
      })
      .catch((error: any) => {
        setError(error.response.data.message);
        setShowAlert(true);
      });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const renderContent = () => {
    return (
      <>
        <Grid sx={styles.box1}>
          <Grid sx={styles.box2}>
            <Box sx={styles.box}>
              <IconButton onClick={handleGoBack} sx={styles.icon}>
                <ArrowBackIcon />
              </IconButton>
              <h2 style={styles.h2}>{RoleFormEnum.AGREGAR_IGLESIA}</h2>
              <div></div>
            </Box>
            <Grid container spacing={2} sx={styles.renderContentGrid}>
              <Grid item xs={6}>
                {inGeneratedTurn && (
                  <LocationSelect
                    id={Locations.COUNTRY}
                    label={Locaciones.PAIS}
                    options={countries ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    onChange={handleCountryChange}
                    size={Sizes.SMALL}
                    loadState={loadState}
                    errors={!!errors?.country}
                    helperText={errors?.country ?? ""}
                    value={null}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {inGeneratedTurn && (
                  <LocationSelect
                    id={Locations.PROVINCE}
                    label={Locaciones.PROVINCIA}
                    loadState={loadState}
                    options={provinces ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    onChange={handleProvinceChange}
                    size={Sizes.SMALL}
                    errors={!!errors?.province}
                    helperText={errors?.province ?? ""}
                    value={null}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {inGeneratedTurn && (
                  <LocationSelect
                    id={Locations.PART}
                    label={Locaciones.PARTIDO}
                    options={parts ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    loadState={loadState}
                    onChange={handlePartChange}
                    size={Sizes.SMALL}
                    errors={!!errors?.part}
                    helperText={errors?.part ?? ""}
                    value={null}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {inGeneratedTurn && (
                  <LocationSelect
                    id={IGLESIA}
                    label={IGLESIA_CAPITALIZED}
                    options={iglesias ?? []}
                    disabled={loadState.current == LoadState.LOADED}
                    loadState={loadState}
                    onChange={(key: any, value: any) => {
                      setIglesia(value);
                    }}
                    size={Sizes.SMALL}
                    errors={!!errors?.part}
                    helperText={errors?.part ?? ""}
                    value={null}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size={Sizes.SMALL}
                  margin={Sizes.NORMAL}
                  fullWidth
                  error={!!errors?.part}
                >
                  <InputLabel id={id}>{RoleFormEnum.ROL_IN_IGLESIA}</InputLabel>
                  <Select
                    labelId={id}
                    id={id}
                    label={RoleFormEnum.ROL_IN_IGLESIA}
                    disabled={false}
                    onChange={onChangeRol}
                    value={roleSelected}
                  >
                    {rolesIglesia &&
                      rolesIglesia.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={styles.alert}>
                {showAlert &&
                  (error ? (
                    <Alert style={styles.errorStyle} severity="error">
                      {error}
                    </Alert>
                  ) : (
                    <Alert style={styles.errorStyleOk} severity="success">
                      {message}
                    </Alert>
                  ))}
              </Grid>
              <Button
                onClick={() => handleSubmmit()}
                variant={CONTAINED}
                color={InOrder.PRIMARY}
                type={FormTextSend.SUBMIT}
                sx={styles.button}
                fullWidth
              >
                {GUARDAR}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Box sx={styles.boxPrincipal}>
      <Paper sx={styles.paper}>{renderContent()}</Paper>
    </Box>
  );
};

export default AddRoleForm;
