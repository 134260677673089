import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import iglesiasService from "../../services/iglesias";
import GenericTable from "../../components/genericTable/GenericTable";
import sectorService from "../../services/sectores";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import { convertUTCToLocal } from "../../utils/datesUtils/dateUtils";

const SectoresList = () => {
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = React.useState(new Date());

  const sectorColumns = [
    { name: "Id", accessor: "id" },
    { name: "Name", accessor: "description" },
  ];

  const sectorActions = [
    {
      icon: () => <EditIcon />,
      onClick: async (row: any) => {
        const sector = await sectorService.findSectorById(row.id);
        sector.horarioSector = sector.horarioSector.map((horario: any) => ({
          ...horario,
          horarioInicial: horario.horarioInicial
            ? convertUTCToLocal(horario.horarioInicial)
            : null,
          horarioFinal: horario.horarioFinal
            ? convertUTCToLocal(horario.horarioFinal)
            : null,
        }));
        if (sector) {
          //@ts-ignore
          navigate(`/configuracion/sectores/edit/${sector.id}`, {
            state: { sector },
          });
        } else {
          console.log("Error al buscar el sector");
        }
      },
    },
    {
      icon: () => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar este sector?",
        );
        if (confirmDelete) {
          sectorService
            .deleteSector(row.id)
            .then((response: any) => {
              setForceRefresh(new Date());
              ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
                type: "success",
                message: "Se elimino el sector correctamente",
              });
            })
            .catch((error: any) => {});
        }
      },
    },
  ];
  /**
   * Fetch sectors
   * @returns
   */
  const fetchSectors = async (page: number, pageSize: number) => {
    let response;
    try {
      response = await iglesiasService.getSectoresList(page, pageSize);
    } catch (error) {
      return [];
    }
    return response;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0.5em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
        }}
      >
        <p style={{ color: "black" }}>Sectores</p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            alignContent: "flex-end",
          }}
        >
          <div>
            <Button
              style={{ backgroundColor: "#3A8DBE" }}
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/configuracion/sectores/create");
              }}
            >
              Crear Sector
            </Button>
          </div>
        </div>
        <GenericTable<any>
          refresh={forceRefresh}
          columns={sectorColumns}
          actions={sectorActions}
          title="Sectors"
          fetchData={fetchSectors}
        />
      </Box>
    </Box>
  );
};

export default SectoresList;
