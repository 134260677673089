// src/components/DatosPadre.tsx
import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import {
  DataPersonal,
  DatosPersonales,
  Sizes,
  State,
} from "../../../enums/components/componentsEnum";
import { PeopleActividadParroquialStyles } from "./PeopleActividadParroquialStyles";
import DocumentTypeComponent from "../../../components/autocomplete/documentType/DocumentTypeComponent";
import { getDocumentTypes } from "../../../utils/forms/formsUtils";
import { DatosPadreProps } from "../InterfacesActividadParroquial";
import { AltaParticipanteTextos } from "../../../enums/pages/CreateActividadParroquialEnum";
import LocationForm from "../../../components/forms/genericLocationForm/LocationForm";

const DatosPadre: React.FC<DatosPadreProps> = ({
  participante,
  handleFormChange,
  disabledFather,
  inputLabelStyles,
  birthPlaces,
  onChangeBirthPlaces,
  isFetch,
  loadState,
  handleFetchPerson,
  resetStateValidator,
  setParticipante,
}) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
          {AltaParticipanteTextos.DATOS_PADRE}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.NAME}_father`}
          label={DatosPersonales.NAME}
          InputLabelProps={{ sx: inputLabelStyles }}
          type="text"
          value={participante?.father?.name ?? ""}
          onChange={(e) => handleFormChange("father.name", e.target.value)}
          style={{ borderRadius: "5px" }}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.SURNAME}_father`}
          label={DatosPersonales.SURNAME}
          type="text"
          InputLabelProps={{ sx: inputLabelStyles }}
          value={participante?.father?.surname ?? ""}
          onChange={(e) => handleFormChange("father.surname", e.target.value)}
          style={{ borderRadius: "5px" }}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <DocumentTypeComponent
          id={`${DataPersonal.DOCUMENT_TYPE}_father`}
          InputLabelProps={{ sx: inputLabelStyles }}
          label="Tipo"
          style={{ borderRadius: "5px" }}
          countries={getDocumentTypes()}
          onFocus={resetStateValidator}
          onBlur={() =>
            handleFetchPerson(
              participante.father.document,
              participante.father.document_type,
              "father",
            )
          }
          value={participante.father.document_type ?? ""}
          onChange={(e: any, value: any) => {
            setParticipante((prev: any) => ({
              ...prev,
              father: {
                ...prev.father,
                document_type: value.id,
              },
            }));
          }}
          size={Sizes.SMALL}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          id={`${DataPersonal.DOCUMENT}_father`}
          label={DatosPersonales.NUMBER_DOCUMENT}
          type="text"
          InputLabelProps={{ sx: inputLabelStyles }}
          style={{ borderRadius: "5px" }}
          value={participante.father.document ?? ""}
          onBlur={() =>
            handleFetchPerson(
              participante.father.document,
              participante.father.document_type,
              "father",
            )
          }
          onChange={(e) => {
            if (participante.father.document_type === "SIN_DNI") {
              alert(
                "Primero cambia a otro tipo de documento antes de modificar el número de documento.",
              );
            } else {
              handleFormChange("father.document", e.target.value);
            }
          }}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={`${DataPersonal.BIRTHDATE}_father`}
          label={DatosPersonales.FECHA_NACIMIENTO}
          type="date"
          value={participante.father.birthdate ?? ""}
          InputLabelProps={{ sx: inputLabelStyles }}
          style={{ borderRadius: "5px" }}
          onChange={(e) => handleFormChange("father.birthdate", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={DataPersonal.EMAIL}
          label={DatosPersonales.EMAIL}
          type="text"
          autoComplete={State.OFF}
          value={participante.father.email || ""}
          onChange={(e) => handleFormChange("father.email", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
          error={false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id={DataPersonal.PHONE}
          label={DatosPersonales.TELEFONO}
          type="number"
          autoComplete={State.OFF}
          value={participante.father.phone || ""}
          onChange={(e) => handleFormChange("father.phone", e.target.value)}
          margin={Sizes.NORMAL}
          size={Sizes.SMALL}
          fullWidth
        />
      </Grid>
      <LocationForm
        formData={birthPlaces}
        onChange={onChangeBirthPlaces}
        nacimiento={true}
        isFetch={isFetch}
        loadState={loadState}
      />
    </Grid>
  );
};

export default DatosPadre;
