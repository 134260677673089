import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class PeopleService extends GenericService {
  constructor() {
    super();
  }

  async getPeople(): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get(`/people`);
      return response as any;
    } catch (error) {
      console.log("Error al obtener las personas");
      return [];
    }
  }

  async findePeopleById(id: string): Promise<any> {
    const response: AxiosResponse<any> = await this.get(
      `/people/findById/${id}`,
    );
    return response as any;
  }

  async findPeople(document: string, documentType: string): Promise<any[]> {
    if (document === undefined || documentType === undefined) {
      throw new Error(
        "El documento y el tipo de documento no pueden ser undefined",
      );
    }

    if (document === null || documentType === null) {
      throw new Error("El documento y el tipo de documento no pueden ser null");
    }

    const response: AxiosResponse<any[]> = await this.get(
      `/people/find/${document}/${documentType}`,
    );
    return response as any;
  }

  async findPeopleForActivityParroquial(
    document: string,
    documentType: string,
  ): Promise<any[]> {
    if (document === undefined || documentType === undefined) {
      throw new Error(
        "El documento y el tipo de documento no pueden ser undefined",
      );
    }

    if (document === null || documentType === null) {
      throw new Error("El documento y el tipo de documento no pueden ser null");
    }

    const response: AxiosResponse<any[]> = await this.get(
      `/people/findForActivity/${document}/${documentType}`,
    );
    return response as any;
  }

  async findBautismoByDocumentAndType(
    document: string,
    documentType: string,
  ): Promise<any[]> {
    const response: AxiosResponse<any[]> = await this.get(
      `/people/find-bautismo/${document}/${documentType}`,
    );
    return response as any;
  }

  async findPeopleUserRolCoordinatorRelatedWithActivityParroquial(
    idActivityParroquial: number,
  ): Promise<any[]> {
    const response: AxiosResponse<any[]> = await this.get(
      `/people/findUserRolCoordinatorRelatedWithActivityParroquial/${idActivityParroquial}`,
    );
    return response as any;
  }
}

const peopleService = new PeopleService();

export default peopleService;
