import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import NavLinkAdministratorGroups from "../createActividadParroquial/LinksAdministrationGroup";
import {
  ActividadParroquialRoutes,
  ActividadParroquialTextos,
  actividadParroquialTexts,
  CreateActividadParroquialEnum,
  ValuesEnum,
  ValuesLabelEnum,
} from "../../../enums/pages/CreateActividadParroquialEnum";
import { stylesActividadParroquial } from "../createActividadParroquial/CreateActividadParroquialStyles";
import { stylesCreateTurno } from "../../turnosV2/Createturno/stylesCreateTurno";
import { Sizes } from "../../../enums/components/componentsEnum";
import { handleGoBack } from "../../../utils/pages/SectorUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import actividadParroquialService from "../../../services/actividadParroquial/ActividadParroquialService";
import {
  ActividadParroquialNombre,
  Group,
} from "../InterfacesActividadParroquial";
import grupoParroquialService from "../../../services/grupoParroquial/GrupoParroquialService";
import Paper from "@mui/material/Paper";
import { showPeopleStyles } from "./ShowPeopleActividadParroquialStyles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import turnoExpecificationService from "../../../services/turnosExpecification";
import participanteActividadParroquialService from "../../../services/ParticipanteActividadParroquial/ParticipanteActividadParroquialService";
import { sortByProperty } from "../../../utils/actividadParroquialUtils/UtilsActividadParroquial";

const ShowParticipantesActividadParroquial: React.FC = () => {
  const navigate = useNavigate();
  const [actividadParroquialNombres, setActividadParroquialNombre] = useState<
    ActividadParroquialNombre[]
  >([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [ActividadParroquialNameId, setActividadParroquialNameId] = useState(0);
  const [groupParroquialId, setGroupParroquialId] = useState(0);
  const [encuentro, setEncuentro] = useState([]);
  const [turnoId, setTurnoId] = useState(0);
  const [participantes, setParticipantes] = useState([]);
  const [nameOfActivity, setNameOfActivity] = useState("");

  useEffect(() => {
    actividadParroquialService
      .getActividadNombres()
      .then((response: any) => {
        const sortedResponse = sortByProperty(response, "name");
        setActividadParroquialNombre(sortedResponse);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  const handleItemChange = (
    value: any,
    type: "groupId" | "nameId" | "turnoId",
  ) => {
    if (type === "groupId") {
      setGroupParroquialId(value);
      actividadParroquialService
        .findActividadByActivityNameIdAndGroupId(
          ActividadParroquialNameId,
          value,
        )
        .then((response: any) => {
          setNameOfActivity(response.actitiviyParroquialName);
          turnoExpecificationService
            .getEncuentroByActivityId(response.id)
            .then((response: any) => {
              setEncuentro(response[0]);
              setParticipantes(response[0]?.participantes);
            })
            .catch((error: any) => {
              console.error("Error al traer turnos", error);
            });
        })
        .catch((error: any) => {
          console.error("Error al buscar actividad parroquial", error);
        });
    } else if (type === "nameId") {
      setActividadParroquialNameId(value);

      grupoParroquialService
        .getGrupoWithOnlyIdAndName(value)
        .then((response: any) => {
          const sortedResponse = sortByProperty(response, "name");
          setGroups(sortedResponse);
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else if (type === "turnoId") {
      setTurnoId(value);
      participanteActividadParroquialService
        .getParticipantesByTurnoExpecificationId(value)
        .then((response: any) => {
          setParticipantes(response);
        })
        .catch((error: any) => {
          console.error("Error al traer participantes", error);
        });
    }
  };

  const handleOnClick = (
    document: string,
    documentType: string,
    id: number,
  ) => {
    navigate(
      `/panel/actividad-parroquial/edit-participantes/${document}/${documentType}`,
      {
        state: {
          ActividadParroquialNameId,
          groupParroquialId,
          turnoId,
          id,
          nameOfActivity,
        },
      },
    );
  };

  return (
    <Box sx={stylesActividadParroquial.box1}>
      <Box sx={stylesActividadParroquial.box2}>
        <Box sx={stylesActividadParroquial.box3}>
          <IconButton onClick={() => handleGoBack(navigate)} sx={{}}>
            <ArrowBackIcon />
          </IconButton>
          <Typography style={stylesActividadParroquial.typography}>
            {CreateActividadParroquialEnum.CATEDRAL_DE_LA_PLATA}
          </Typography>
        </Box>

        <Box sx={stylesCreateTurno.containerLinks}>
          <Typography variant="subtitle1" sx={stylesActividadParroquial.title}>
            {ActividadParroquialTextos.TituloAdministracionGrupo}
          </Typography>
          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.AltaActividad}
            label={ActividadParroquialTextos.AltaActividad}
            isActiveStyle={stylesActividadParroquial.activeLink}
            defaultStyle={stylesActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            label={ActividadParroquialTextos.ProgramarEncuentro}
            isActiveStyle={stylesActividadParroquial.activeLink}
            defaultStyle={stylesActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.ListadoEncuentros}
            label={ActividadParroquialTextos.ListadoEncuentros}
            isActiveStyle={stylesActividadParroquial.activeLink}
            defaultStyle={stylesActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.ListadoIntegrantes}
            label={ActividadParroquialTextos.ListadoIntegrantes}
            isActiveStyle={stylesActividadParroquial.activeLink}
            defaultStyle={stylesActividadParroquial.italicLink}
          />
          <NavLinkAdministratorGroups
            to={ActividadParroquialRoutes.AltaParticipantes}
            label={ActividadParroquialTextos.AltaParticipantes}
            isActiveStyle={stylesActividadParroquial.activeLink}
            defaultStyle={stylesActividadParroquial.italicLink}
          />
        </Box>
      </Box>
      <div style={stylesActividadParroquial.divForm}>
        <Grid container alignItems="center">
          <Grid style={stylesCreateTurno.gridTipo} item xs={11.5}>
            <Typography style={stylesCreateTurno.typographyTipo}>
              {CreateActividadParroquialEnum.SELECCIONE_LA_ACTIVIDAD_PARROQUIAL}
            </Typography>
            <Select
              labelId={ValuesLabelEnum.NAME_ID}
              id={ValuesEnum.NAME_ID}
              value={ActividadParroquialNameId || ""}
              fullWidth
              size={Sizes.SMALL}
              style={stylesCreateTurno.selectTipo}
              disabled={false}
              required
              onChange={(e) => {
                handleItemChange(e.target.value as any, "nameId");
              }}
            >
              {actividadParroquialNombres &&
                actividadParroquialNombres.map((actividadParroquial: any) => (
                  <MenuItem
                    key={actividadParroquial.id}
                    value={actividadParroquial.id}
                  >
                    {actividadParroquial.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid style={stylesCreateTurno.gridTipo} item xs={11.5}>
            <Typography style={stylesCreateTurno.typographyTipo}>
              {CreateActividadParroquialEnum.SELECCIONE_GRUPO}
            </Typography>
            <Select
              labelId={ValuesLabelEnum.GROUP_ID}
              id={ValuesEnum.GROUP_ID}
              value={groupParroquialId || ""}
              fullWidth
              size={Sizes.SMALL}
              style={stylesCreateTurno.selectTipo}
              disabled={false}
              required
              onChange={(e) => {
                handleItemChange(e.target.value as any, "groupId");
              }}
            >
              {groups &&
                groups.map((groupParroquialName: any) => (
                  <MenuItem
                    key={groupParroquialName.id}
                    value={groupParroquialName.id}
                  >
                    {groupParroquialName.name}
                  </MenuItem>
                ))}
            </Select>
            <Grid style={stylesActividadParroquial.typographyTipoLast}>
              {groupParroquialId
                //@ts-ignore
                ? encuentro?.parentTurnoId
                  ? actividadParroquialTexts.GRUPO_WITH_MET_ASIGNED
                  : actividadParroquialTexts.REMEMBER_SCHEDUL_THIS_MET_FOR_ACT_PARROQUIAL
                : null}
            </Grid>
          </Grid>

          <Grid item xs={11.5} sx={showPeopleStyles.gridContainerTable}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Apellido</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participantes?.map((participante: any) => (
                    <TableRow
                      key={participante.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {participante.participante.name}
                      </TableCell>
                      <TableCell>{participante.participante.surname}</TableCell>
                      <TableCell>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleOnClick(
                              participante.participante.document,
                              participante.participante.document_type,
                              participante.id,
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default ShowParticipantesActividadParroquial;
