import { CSSProperties } from "react";

export const stylesListadoActividadParroquial: Record<string, CSSProperties> = {
  box1: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
  },
  box2: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "3.2%",
    paddingLeft: "5em",
    paddingRight: "2em",
  },
  box3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconButton: {
    marginTop: "0.1em",
  },
  typography: {
    color: "#264B99",
    marginTop: "0.5em",
    fontSize: "1.1em",
  },
  containerLinks: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 0",
    marginTop: "16px",
  },
  title: {
    borderBottom: "1px solid black",
    marginBottom: "20px",
  },
  italicLink: {
    display: "block",
    marginTop: "8px",
    textDecoration: "none",
    color: "grey",
    fontStyle: "italic",
    paddingBottom: "8px",
  },
  activeLink: {
    color: "#264B99",
  },
  boxPrincipalListado: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
    width: "75%",
    padding: "0",
    overflow: "auto",
  },
  divListado: {
    paddingBottom: "25px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignContent: "flex-end",
  },
  gridActividadParroquialInputs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "5%",
    paddingLeft: "2em",
    width: "90%",
  },
  box2New: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "4.6%",
    paddingRight: "5em",
  },
};
