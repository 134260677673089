import { SelectChangeEvent } from "@mui/material";
import { Scope } from "../../enums/ScopeEnum";
export interface Coordinator {
  id: number | null;
  name: string;
  surname: string;
  documentType: string;
  document: string;
  birthdate: string;
  email: string;
  phone: string;
  rolesIglesia?: any[];
}
export interface ActividadParroquialState {
  nameId: number;
  groupId: number;
  detail: string;
  scope: Scope | null;
  coordinators: Coordinator[];
  task: string;
  liturgiaId?: number;
}

export function initializeActividadParroquial(): ActividadParroquialState {
  return {
    nameId: 0,
    groupId: 0,
    detail: "",
    scope: null,
    coordinators: [
      {
        id: null,
        name: "",
        surname: "",
        documentType: "",
        document: "",
        birthdate: "",
        email: "",
        phone: "",
        rolesIglesia: [],
      },
    ],
    task: "",
    liturgiaId: 0,
  };
}

export const handleTypeChange = (
  event: SelectChangeEvent<string>,
  setTurno: Function,
) => {
  setTurno((prevTurno: any) => ({
    ...prevTurno,
    type: event.target.value,
  }));
};

export function assignActividadData(
  prevState: ActividadParroquialState,
  coordinatorsData: any[],
): ActividadParroquialState {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const updatedCoordinators = coordinatorsData.map((coordinator) => ({
    ...coordinator,
    birthdate: coordinator?.birthdate
      ? formatDate(coordinator.birthdate)
      : prevState.coordinators.find((c) => c.id === coordinator.id)?.birthdate,
  }));

  return {
    ...prevState,
    coordinators: updatedCoordinators,
  };
}
