import { useEffect, useState } from "react";
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import moment from "moment";
//@ts-ignore
import BigCalendar from "react-big-calendar-like-google";
import { useEventContext } from "../contexts/EventContext";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { ScheduleAppointmentEnum } from "../../../enums/components/componentsEnum";
import { stylesCreateTurno } from "../../turnosV2/Createturno/stylesCreateTurno";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ImageAspectRatioIcon from "@mui/icons-material/ImageAspectRatio";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { SacramentoEnum } from "../../../enums/SacramentoEnum";
import PaletteIcon from "@mui/icons-material/Palette";
import { handleDayProperties } from "../utils/CalendarUtils";
import EventModal from "./modals/EventModal";
import { changeTimezoneToUTC } from "../../../utils/datesUtils/dateUtils";

moment.locale("es");
BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
const currentDate = moment();

export default function Calender() {
  //@ts-ignore
  const { events, groups, init, typesSelected, changeSelectedType, changeSelectedGroups, groupsSelected, handleClickOpen,
  } = useEventContext();
  // const [currentMonth, setCurrentMonth] = useState<number>((new Date()).getMonth());
  const [collapsed, setCollapsed] = useState(false);
  const [mostrarEventos, setMostrarEventos] = useState(true);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const toggleMostrarTodos = () => {
    setMostrarEventos(!mostrarEventos);
  };

  const changeCheckBox = (item: any) => {
    if (groupsSelected.includes(item)) {
      changeSelectedGroups(groupsSelected.filter((i: any) => i !== item));
    } else {
      groupsSelected.push(item);
      changeSelectedGroups(groupsSelected);
    }
  };

  const handleRadioChange = (key: any) => {
    const newTypesSelected = new Set(typesSelected);
    if (newTypesSelected.has(key)) {
      newTypesSelected.delete(key);
    } else {
      newTypesSelected.add(key);
    }
    //@ts-ignore
    changeSelectedType([...newTypesSelected]);
  };

  //TODO this can be done in the backend to remove events that do not have associated simple shifts
  const eventosFiltrados = mostrarEventos
    ? events.filter(
        (evento: any) =>
          evento.turnoSimpleId !== undefined && evento.turnoSimpleId !== null,
      )
    : [];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <div className={`container ${collapsed ? "collapsed" : ""}`}>
          <div className="sidebar">
            <div style={{ position: "relative" }}>
              <StaticDatePicker
                defaultValue={null}
                value={null}
                onChange={() => {}}
                disablePast
                orientation={ScheduleAppointmentEnum.PORTRAIT}
                minDate={currentDate}
                //shouldDisableDate={}
                showDaysOutsideCurrentMonth
                sx={stylesCreateTurno.staticDatePicker}
                slotProps={{
                  actionBar: { actions: [] },
                  day: ({ day }) => handleDayProperties(day, eventosFiltrados),
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                borderBottom: "1px solid grey",
                opacity: "0.8",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ImageAspectRatioIcon />
                <Typography style={{ paddingLeft: "5px" }}>Sectores</Typography>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  defaultChecked
                  onChange={() => {
                    toggleMostrarTodos();
                  }}
                  style={{ color: "grey" }}
                  value={false}
                />
                <Typography style={{ paddingLeft: "5px" }}>Todos</Typography>
              </div>
            </div>
            <div className="scroleable-list">
              {groups.map((item: any) => (
                <div key={item.id} className="group-item">
                  <div style={{ flex: 0.3 }}>
                    <Checkbox
                      defaultChecked
                      onChange={() => changeCheckBox(item.id)}
                      value={groupsSelected?.includes(item.id)}
                      style={{ color: item.color }}
                    />
                  </div>

                  <div style={{ flex: 1 }}>
                    <p>{item.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="calendar">
            <div
              style={{ height: "100vh", paddingLeft: "2%", paddingRight: "2%" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  justifyItems: "center",
                }}
              >
                <Button onClick={toggleSidebar}>
                  <FullscreenIcon
                    style={{ height: "40px", color: "black", width: "40px" }}
                  />
                </Button>
                <p style={{ color: "black", paddingRight: "5%" }}>
                  Pantalla completa
                </p>

                <Checkbox
                  defaultChecked
                  onChange={() => changeCheckBox(0)}
                  value={groupsSelected?.includes(0)}
                  style={{ color: "red" }}
                />
                <p style={{ color: "black" }}>Feriados</p>
                <div style={{ paddingLeft: "5%" }}>
                  {Object.keys(SacramentoEnum).map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Radio
                          checked={new Set(typesSelected).has(key)}
                          onClick={() => handleRadioChange(key)}
                        />
                      }
                      label={key.charAt(0) + key.slice(1).toLowerCase()} // Capitalizar la primera letra del key
                    />
                  ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={init}>
                    <PaletteIcon style={{ color: "#333" }} />
                  </Button>
                  <Button
                    onClick={toggleSidebar}
                    style={{ backgroundColor: "#333", color: "white" }}
                  >
                    RESET
                  </Button>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="col p-2">
                    <EventModal />
                  </div>
                </div>
              </div>
              <BigCalendar
                popup
                selectable
                events={eventosFiltrados}
                startAccessor={(event: any) => {
                  return changeTimezoneToUTC(event.start);
                }}
                endAccessor={(event: any) => {
                  return changeTimezoneToUTC(event.end);
                }}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                //TODO add logic for fetch for actually month
                // onNavigate={(e: any) => setCurrentMonth(new Date(e).getMonth())}
                onSelectEvent={(event: any) => {
                  const data = {
                    _id: event._id,
                    turnoId: event.turnoId,
                    title: event.title,
                    description: event.description,
                    bgColor: event.bgColor,
                    start: event.start,
                    end: event.end,
                    count: event.count,
                    turnoSimpleId: event.turnoSimpleId,
                  };
                  handleClickOpen(data);
                }}
                // onSelectSlot={(slotInfo: any) => {
                //   const data = {
                //     start: slotInfo.start,
                //     end: slotInfo.end,
                //   };
                //   handleClickOpen(data);
                // }}
              />
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
}
