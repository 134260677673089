import axios, { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class ColegioService extends GenericService {
  constructor() {
    super();
  }

  async findAll(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get("/colegio");
      return response;
    } catch (error: any) {
      console.log("Error al obtener todos los grupos parroquiales");
      return [];
    }
  }

  async findByLocalityId(localityId: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/colegio/findByLocalityId/${localityId}`,
      );
      return response;
    } catch (error: any) {
      console.log("Error al obtener todos los colegios segun la localidad");
      return [];
    }
  }

  async findByLocalityIdAndSchoolLevelId(
    localityId: number,
    schoolLevelId: number,
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        `/colegio/findByLocalityIdAndSchoolLevelId/${localityId}/${schoolLevelId}`,
      );
      return response;
    } catch (error: any) {
      console.log(
        "Error al obtener todos los colegios segun la localidad y nivel escolar",
      );
      return [];
    }
  }

  async findSchoolLevels(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/colegioniveleducativo/unique-codes",
      );
      return response;
    } catch (error: any) {
      console.log("Error al obtener todos los niveles escolares");
      return [];
    }
  }
}

const colegioService = new ColegioService();

export default colegioService;
