import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import "moment/locale/es";
import calendarioService from "../../../services/calendario";
import React from "react";
import { Dayjs } from "dayjs";
import { Slot } from "../../../interfaces/interfacesGeneral/interfacesSchedule";
import {
  DAYS,
  Directions,
  Errors,
  FLEX,
  RESERVED,
  ScheduleAppointmentEnum,
  TimeUnits,
  Turnos,
} from "../../../enums/components/componentsEnum";
import { styles, stylesSchedule } from "./ScheduleAppointmentStyles";
import {
  handleOnClick,
  isFeriado,
} from "../../../utils/scheduleAppointment/scheduleAppointmentUtils";
import {
  convertUTCToLocal,
  getOnlyDate,
} from "../../../utils/datesUtils/dateUtils";
import feriadosService from "../../../services/feriados";
import { ValuesEnum } from "../../../enums/pages/CreateTurnoEnum";

moment.locale("es");

const ScheduleAppointment: React.FC<any> = ({
  errors,
  dateSelected,
  turnoType,
  handleStepComplete,
}) => {
  const [selectedRange, setSelectedRange] = useState<Slot | null>(null);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [hoveredDay, setHoveredDay] = React.useState<Dayjs | null>(null);
  const [feriados, setFeriados] = useState<any>([]);

  const [selectedTimeSlots, setSelectedTimeSlots] = useState<
    {
      start: string;
      end: string;
      turnoId: number;
      specificationId: number;
      limit: number;
      count: number;
      startTime?: string;
      endTime?: string;
      criteryFeriados?: string;
      startHourSpecial?: string;
      endHourSpecial?: string;
    }[]
  >([]);

  const [selectedTimeSlotsCalendar, setSelectedTimeSlotsCalendar] = useState<
    { startTime: string; endTime: string }[]
  >([]);

  const currentDate = moment();

  useEffect(() => {
    try {
      const formatDate = new Date(
        moment().format(ScheduleAppointmentEnum.FORMAT_DATE),
      );
      dateSelected.current = { ...dateSelected.current, day: currentDate };

      //TODO unify
      calendarioService.getTurnosByMonthV2(formatDate).then((eventsBusy) => {
        if (!!eventsBusy && eventsBusy.length > 0) {
          setSelectedTimeSlotsCalendar(
            eventsBusy
              .filter((t: any) => t.type == turnoType)
              .map((t) => {
                return {
                  startTime: getOnlyDate(t.startTime),
                  endTime: getOnlyDate(t.endTime),
                };
              }),
          );
        }
      });

      calendarioService
        .getTurnosByDate(formatDate, turnoType)
        .then((eventsBusy) => {
          setSelectedTimeSlots(eventsBusy);
        });

      feriadosService.getFeriadosForCalendar().then((feriados) => {
        setFeriados(feriados);
      });
    } catch (error) {
      console.error(Errors.ERROR_FETCHING_AVAILABLE_DAYS, error);
    }
  }, []);

  const handleDateChange = async (date: Moment | null) => {
    if (date) {
      try {
        const formatDate = new Date(
          date.format(ScheduleAppointmentEnum.FORMAT_DATE),
        );
        const eventsBusy = await calendarioService.getTurnosByDate(
          formatDate,
          turnoType,
        );
        setSelectedTimeSlots(eventsBusy);
        dateSelected.current = { ...dateSelected.current, day: date };
        setSelectedDate(date);
      } catch (error) {
        console.error(Errors.ERROR_FETCHING_AVAILABLE_DAYS, error);
      }
    }
  };

  const isDateDisabled = (date: Moment) => {
    const today = moment();
    const diffInDays = date.diff(today, DAYS);
    return diffInDays > 365;
  };

  const handleRangeChange = (
    start: string | undefined,
    end: string | undefined,
    turnoId: number,
    specificationId: number,
    limit: number,
    count: number,
    index: number,
  ) => {
    if (start !== undefined && end !== undefined) {
      setSelectedRange({
        start,
        end,
        index,
        turnoId,
        specificationId,
        limit,
        count,
      });
      dateSelected.current = {
        ...dateSelected.current,
        start: start,
        end: end,
        turnoId: turnoId,
        specificationId: specificationId,
        limit: limit,
        count: count,
      };
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container style={styles.grid}>
        <Grid
          item
          lg={5}
          justifyContent={Directions.CENTER}
          display={FLEX}
          alignContent={Directions.CENTER}
          alignItems={Directions.CENTER}
        >
          <Box sx={styles.box}>
            <div style={{ justifyContent: Directions.CENTER }}>
              <Typography sx={styles.typography}>
                {ScheduleAppointmentEnum.SELECCIONE_FECHA}
              </Typography>
              <StaticDatePicker
                defaultValue={moment()}
                onChange={handleDateChange}
                disablePast
                orientation={ScheduleAppointmentEnum.PORTRAIT}
                minDate={currentDate}
                shouldDisableDate={isDateDisabled}
                showDaysOutsideCurrentMonth
                sx={styles.staticDatePicker}
                slotProps={{
                  actionBar: { actions: [] },
                  day: ({ day }) => {
                    const isInRange = selectedTimeSlotsCalendar.some((slot) => {
                      // Todo revisar
                      const startDay = moment(slot.startTime).startOf(
                        TimeUnits.DAYS,
                      );

                      const currentDay = moment(day).startOf(TimeUnits.DAYS);
                      return currentDay.isBetween(
                        startDay,
                        startDay,
                        null,
                        "[]",
                      );
                    });

                    const isSelectedDay = moment(day).isSame(
                      selectedDate,
                      TimeUnits.DAYS,
                    );
                    const isToday = moment(day).isSame(
                      moment(),
                      TimeUnits.DAYS,
                    );
                    const isDisabled = moment(day).isBefore(
                      currentDate,
                      TimeUnits.DAYS,
                    );

                    const style =
                      isFeriado(day, feriados) &&
                      !isSelectedDay &&
                      !isDisabled &&
                      !isToday
                        ? { backgroundColor: "#ffcccc" }
                        : isInRange && !isSelectedDay && !isDisabled && !isToday
                          ? { backgroundColor: "lightblue" }
                          : {};

                    return {
                      style,
                      "data-selected-day": selectedDate,
                      "data-hovered-day": hoveredDay,
                      onPointerEnter: () =>
                        setHoveredDay(dateSelected.current.day),
                      onPointerLeave: () => setHoveredDay(null),
                    };
                  },
                }}
              />
              <Typography
                variant={ScheduleAppointmentEnum.CAPTION}
                color={Errors.ERROR}
                sx={styles.typography1}
              >
                {errors?.date}
              </Typography>
            </div>
          </Box>
        </Grid>
        <Grid item lg={7}>
          <Typography sx={styles.typography2}>
            {Turnos.TURNOS_DISPONIBLES}
          </Typography>
          <Box sx={styles.box1}>
            <Grid container justifyContent={Directions.CENTER}>
              {selectedTimeSlots.map((timeSlot: any, index) => {
                const usarHorarioEspecial =
                  isFeriado(selectedDate, feriados) &&
                  timeSlot.criteryFeriados === ValuesEnum.HORARIO_ESPECIAL;

                return (
                  <Grid
                    item
                    lg={12}
                    justifyContent={Directions.CENTER}
                    key={index}
                  >
                    <div
                      onClick={() =>
                        handleOnClick(
                          timeSlot,
                          index,
                          handleRangeChange,
                          handleStepComplete,
                        )
                      }
                      style={stylesSchedule(selectedRange, index, timeSlot).div}
                      className={
                        timeSlot.state == RESERVED
                          ? ""
                          : ScheduleAppointmentEnum.TURNO_DIV
                      }
                    >
                      <p>
                        {ScheduleAppointmentEnum.SECTOR_PUNTOS}{" "}
                        {timeSlot.sector} -
                        {usarHorarioEspecial
                          ? convertUTCToLocal(timeSlot.startHourSpecial)
                          : convertUTCToLocal(timeSlot.start)}{" "}
                        -
                        {usarHorarioEspecial
                          ? convertUTCToLocal(timeSlot.endHourSpecial)
                          : convertUTCToLocal(timeSlot.end)}{" "}
                        -{ScheduleAppointmentEnum.OCUPACION_PUNTOS}{" "}
                        {timeSlot.count}/{timeSlot.limit}
                      </p>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            {selectedTimeSlots.length === 0 && (
              <Typography>{ScheduleAppointmentEnum.NO_HAY_TURNOS}</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <style>
        {`
            .turno-div:hover {
                background-color: #2C6B9C !important;
                color: gray;
          }
        `}
      </style>
    </LocalizationProvider>
  );
};

export default ScheduleAppointment;
