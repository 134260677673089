import React, { useEffect } from "react";
import { Box, Grid, TableCell, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import eventosService, { EventState } from "../../../services/eventos";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GenericTable from "../../../components/genericTable/GenericTable";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import certificationService from "../../../services/certification";
import { CertificationType } from "../../../utils/cerficates/bautismoEnum";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { addNotification } from "../../../utils/notifications";
import { useQueryClient } from "react-query";
import CompleteEventData from "../../events/CompleteEventData/CompleteEventData";
import { RoutesEnum } from "../../../utils/RoutesEnum";
import DeleteIcon from "@mui/icons-material/Delete";
import ObservableService, {
  ObservableEvents,
} from "../../../services/ObservableService";
import SelectComponent from "../../../components/autocomplete/SelectComponent";
import { RolSacramentoEnum } from "../../../enums/RolSacramentoEnum";
import DialogComponent from "../../certifications/dialogComponent";
import {
  Event,
  People,
  CalendarToday,
  AccessTime,
  Person,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChurchIcon from "@mui/icons-material/Church";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import turnoService from "../../../services/turnos";
import { convertUTCToLocal } from "../../../utils/datesUtils/dateUtils";

export const eventStateColors = {
  [EventState.Pending]: "#FFC107",
  [EventState.Rejected]: "#F44336",
  [EventState.Completed]: "#3F51B5",
};

export const eventEdit = {
  ["Bautismo"]: RoutesEnum.BAUTISMO,
  ["Casamiento"]: RoutesEnum.CASAMIENTOS,
  ["Confirmacion"]: RoutesEnum.CONFIRMACIONES,
  ["Comunion"]: RoutesEnum.COMUNIONES,
};

const eventsColumns = [
  { name: "id", accessor: "id" },
  {
    name: "Estado",
    accessor: "state",
    customReder: (row: any, column: any) => {
      //@ts-ignore
      return (
        //@ts-ignore
        <TableCell style={{ color: eventStateColors[row.state] }}>
          {row[column.accessor]}
        </TableCell>
      );
    },
  },
  {
    name: "Owner",
    accessor: "rols",
    customReder: (row: any, column: any) => {
      let data = "";
      //TODO move this mapping to backend

      if (
        row?.turno?.type == "Bautismo" ||
        row?.turno?.type == "Comunion" ||
        row?.turno?.type == "Confirmacion"
      ) {
        let owner = row.rols.filter(
          (rol: any) => rol.rol == RolSacramentoEnum.owner,
        );
        if (
          owner.length > 0 &&
          !!owner[0].person.name &&
          !!owner[0].person.surname
        ) {
          data = owner[0].person.name + " " + owner[0].person.surname;
        } else {
          data = "Anonimo";
        }
      } else {
        let owner = row.rols.filter(
          (rol: any) => rol.rol == "husband" || rol.rol == "wife",
        );
        if (
          owner.length > 0 &&
          !!owner[0].person.name &&
          !!owner[0].person.surname
        ) {
          data = owner[0].person.name + " " + owner[0].person.surname;
        } else {
          data = "Anonimo";
        }
      }

      //@ts-ignore
      return <TableCell>{data}</TableCell>;
    },
  },
  {
    name: "Tipo / Dni",
    accessor: "rols",
    customReder: (row: any, column: any) => {
      //@ts-ignore
      return (
        <TableCell>
          {row[column.accessor][0]?.person?.document_type} /{" "}
          {row[column.accessor][0]?.person?.document}
        </TableCell>
      );
    },
  },
];

const EventList = () => {
  const [dialogOpen, setDialogOpen] = React.useState({
    state: false,
    eventId: undefined,
    type: "",
  });
  const location = useLocation();
  const [filters, setFilters] = React.useState([
    {
      param: "status",
      value: new URLSearchParams(location.search).get("status") || "all",
    },
  ]);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const [dialogState, setDialogState] = React.useState({
    state: false,
    pdfUrl: "",
    type: CertificationType.ALL,
  });
  const [error, setError] = React.useState({ state: false, message: "" });

  /**
   * Handle dialog
   */
  const handleDialogOpen = (row: any): void => {
    setDialogOpen({ state: true, eventId: row.id, type: row.type });
  };

  /**
   * Handle dialog close
   */
  const handleDialogClose = () => {
    //Todo clean data for closed
    setDialogOpen({ state: false, eventId: undefined, type: "" });
  };

  /**
   * Handle input change
   * @param e
   */
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
  };

  /**
   * Handle submit
   */
  const handleSubmit = (formData: any, eventId: number | undefined) => {
    eventosService
      .completeEventData(formData, eventId)
      .then((response: any) => {
        setForceRefresh(new Date());
        handleDialogClose();
      });
  };

  /**
   * Fetch dialog
   * @param type
   */
  const fetchDialog = async (type: any, dni: string, documentType: string) => {
    const response = await certificationService.getCertificateV2(
      type,
      dni,
      documentType,
    );
    if (!!response && response instanceof Blob) {
      try {
        const pdfUrl = URL.createObjectURL(response);

        setDialogState((prevState) => ({
          state: !prevState.state,
          pdfUrl: pdfUrl,
          type: type,
        }));
      } catch (error) {}
    }
  };

  /**
   * Actions for events
   */
  const eventsActions = [
    {
      icon: (row: any) =>
        row.type !== "Casamiento" ? (
          <PictureAsPdfIcon
            style={{
              color: row.state == EventState.Completed ? "#87CEFA" : "",
            }}
            titleAccess="Exportar certificaod"
          />
        ) : (
          <></>
        ),
      onClick: (row: any) => {
        if (row.state == EventState.Completed) {
          //TODO change to owner
          fetchDialog(
            row.type,
            row.rols[0]?.person.document,
            row.rols[0]?.person?.document_type,
          );
        } else {
          addNotification(
            queryClient,
            "error",
            "Debe marcar el bautismo como completado",
          );
        }
      },
    },
    {
      icon: (row: any) => <EditIcon />,
      onClick: (row: any) => {
        //@ts-ignore
        navigate(eventEdit[row.type], { state: { eventId: row.id } });
      },
    },

    {
      icon: (row: any) => {
        if (
          row.state == EventState.Pending ||
          row.state == EventState.Incompleted
        ) {
          return (
            <CheckIcon
              titleAccess="Marcar el evento como presente"
              style={{ fill: "#4CAF50" }}
            />
          );
        } else {
          return (
            <ClearIcon
              titleAccess="Desmarcar el evento como presente"
              style={{ fill: "#F44336" }}
            />
          );
        }
      },
      onClick: (row: any) => {
        if (row.state !== EventState.Incompleted) {
          eventosService.updateEventState(row.id, row).then((response: any) => {
            setForceRefresh(new Date());
          });
        } else {
          addNotification(
            queryClient,
            "error",
            "Debe completar los datos necesarios",
          );
        }
      },
    },

    {
      icon: (row: any) => (
        <CloudUploadIcon
          style={{
            color:
              row.state == EventState.Approved ||
              row.state == EventState.Completed
                ? "#87CEFA"
                : "",
          }}
          titleAccess="Completar datos del sacramento"
        />
      ),
      onClick: (row: any) => {
        if (row.state == EventState.Approved) {
          handleDialogOpen(row);
        } else {
          addNotification(
            queryClient,
            "error",
            "Debe marcar el bautismo como presente",
          );
        }
      },
    },
    {
      icon: (row: any) => <DeleteIcon />,
      onClick: (row: any) => {
        const confirmDelete = window.confirm(
          "¿Estás seguro que deseas eliminar este evento?",
        );
        if (confirmDelete) {
          eventosService.deleteEvent(row.id).then((response: any) => {
            setForceRefresh(new Date());
            ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
              type: "success",
              message: "Se elimino el evento correctamente",
            });
          });
        }
      },
    },
  ];

  /**
   * Fetch events
   * @returns
   */
  const fetchEvents = async (
    page: number,
    pageSize: number,
    filter?: string,
  ) => {
    let turnoId: any = id!;
    const response = await eventosService.getEventsByTurnoId(
      turnoId,
      page,
      pageSize,
      filter,
    );
    return response.map((event: any) => {
      return { ...event, type: event.turno?.type };
    });
  };

  //TODO refactor
  const renderFilters = () => {
    return (
      <Grid
        container
        gap={2}
        style={{
          boxShadow: "0px 0px 5px .1px #3176B0",
          borderRadius: "15px",
          margin: "1%",
          padding: "0.5%",
        }}
      >
        <Grid item xs={1}>
          <button
            style={{
              backgroundColor: "transparent",
              height: "100%",
              width: "100%",
              color: "black",
              border: "none",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            Volver
          </button>
        </Grid>
        <Grid item xs={2}>
          <SelectComponent
            id="status"
            label="Estado"
            options={[
              { id: "all", name: "all" },
              { id: "pending", name: "pending" },
              { id: "approved", name: "approved" },
              { id: "completed", name: "completed" },
            ]}
            value={filters.find((filter) => filter.param === "status")?.value}
            onChange={(key: any, value: any) => {
              //TODO extract to a method and refactor
              const newStatus = value.id;
              let oldValueUpdated;
              filters.forEach((filter, index) => {
                if (filter.param === "status" && filter.value !== newStatus) {
                  oldValueUpdated = index;
                }
              });
              if (oldValueUpdated != undefined) {
                let updatedFilters = filters;
                updatedFilters[oldValueUpdated] = {
                  param: "status",
                  value: (updatedFilters[oldValueUpdated].value = newStatus),
                };
                setFilters([...updatedFilters]);
              }
            }}
            size="small"
            errors={false}
            helperText={""}
          />
        </Grid>

        {false && (
          <>
            <Grid item xs={2}>
              <TextField
                id="birthdate"
                label={RolSacramentoEnum.owner}
                type="text"
                value={""}
                onChange={(e) => () => {}}
                margin="normal"
                size="small"
                fullWidth
                error={false}
                helperText={""}
              />
            </Grid>

            <Grid item xs={1}>
              <SelectComponent
                id="country"
                label="Typo"
                countries={
                  //TODO move to backend
                  [
                    { id: "DNI", name: "DNI" },
                    { id: "PASAPORTE", name: "PASAPORTE" },
                    { id: "CUIL", name: "CUIL" },
                    { id: "CUIT", name: "CUIT" },
                    { id: "CDI", name: "CDI" },
                    { id: "TI", name: "TI" },
                    { id: "LC", name: "LC" },
                    { id: "LE", name: "LE" },
                    { id: "CI", name: "CI" },
                  ]
                }
                value={""}
                onChange={() => {}}
                size="small"
                errors={false}
                helperText={""}
              />
            </Grid>

            <Grid item xs={1}>
              <TextField
                id="birthdate"
                label="Numero"
                type="text"
                value={""}
                onChange={(e) => () => {}}
                margin="normal"
                size="small"
                fullWidth
                error={false}
                helperText={""}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                id="birthdate"
                label=""
                type="date"
                value={""}
                onChange={(e) => () => {}}
                margin="normal"
                size="small"
                fullWidth
                error={false}
                helperText={""}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const [turno, setTurno] = React.useState({
    startTime: "",
    startHour: "",
    endHour: "",
    sacerdote: {
      nombre: "",
      apellido: "",
    },
    sector: {
      description: "",
    },
    slots: 0,
    count: 0,
    approvedCount: 0,
    completedCount: 0,
    type: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const turno = await turnoService.getTurnoById(location.state.turnoId);
      setTurno(turno);
    };
    fetchData();
  }, [location.state.turnoId]);

  const eventType = {
    Bautismo: "del bautismo",
    Comunion: "de la comunión",
    Casamiento: "del casamiento",
    Confirmacion: "de la confirmación",
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          width: "90%",
          padding: "0",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "25px",
            padding: "20px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 5px .1px #3176B0",
            borderRadius: "15px",
            width: "96%",
            margin: "10px auto",
            gap: "10px",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
              color: "#333",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            {
              //@ts-ignore
              `Detalles ${eventType[turno.type]}`
            }
          </Typography>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "50%",
              }}
            >
              <Typography variant="body1">
                <CalendarToday sx={{ verticalAlign: "middle" }} /> Fecha:{" "}
                {new Date(turno.startTime).toLocaleDateString()}
              </Typography>
              <Typography variant="body1">
                <AccessTime sx={{ verticalAlign: "middle" }} /> Horario:{" "}
                {convertUTCToLocal(turno.startHour)}-
                {convertUTCToLocal(turno.endHour)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "50%",
              }}
            >
              <Typography variant="body1">
                <ChurchIcon sx={{ verticalAlign: "middle" }} /> Sacerdote:{" "}
                {turno.sacerdote?.nombre} {turno.sacerdote?.apellido}
              </Typography>
              <Typography variant="body1">
                <LocationOnIcon sx={{ verticalAlign: "middle" }} /> Sector:{" "}
                {turno.sector?.description || "No hay sector asignado"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "50%",
              }}
            >
              <Typography variant="body1">
                <People sx={{ verticalAlign: "middle" }} /> Capacidad máxima:{" "}
                {turno.slots}
              </Typography>
              <Typography variant="body1">
                <People sx={{ verticalAlign: "middle" }} /> Turnos reservados:{" "}
                {turno.count}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "50%",
              }}
            >
              <Typography variant="body1">
                <DoneIcon sx={{ verticalAlign: "middle" }} /> Turnos aprobados:{" "}
                {turno.approvedCount}
              </Typography>
              <Typography variant="body1">
                <DoneAllIcon sx={{ verticalAlign: "middle" }} /> Turnos
                completados: {turno.completedCount}
              </Typography>
            </Box>
          </Box>
        </Box>

        <DialogComponent
          open={dialogState.state}
          onClose={() =>
            setDialogState((prevState) => ({ ...prevState, state: false }))
          }
          pdfUrl={dialogState.pdfUrl}
          type={dialogState.type}
          handleOpenDialog={() => {}}
        />

        <GenericTable<any>
          filtersData={filters}
          refresh={forceRefresh}
          columns={eventsColumns}
          actions={eventsActions}
          title="Events"
          fetchData={fetchEvents}
          filters={renderFilters}
        />
        <CompleteEventData
          open={dialogOpen.state}
          eventId={dialogOpen.eventId}
          handleClose={handleDialogClose}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          type={dialogOpen.type}
        />
      </Box>
    </Box>
  );
};

export default EventList;
