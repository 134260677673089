import axios, { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";
import { ErrorMessages } from "../../utils/services";
import { get } from "http";

export class ActividadParroquialService extends GenericService {
  constructor() {
    super();
  }

  async findAll(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/actividadParroquial",
      );
      return response;
    } catch (error: any) {
      console.log("Error al obtener todas las actividades parroquiales");
      return [];
    }
  }

  async editActividad(data: any, id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.put(
        "/actividadParroquial/edit/" + id,
        data,
      );
      return response;
    } catch (error) {
      throw new Error("Error al editar la actividad parroquial");
    }
  }

  async createActividad(data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        "/actividadParroquial/create",
        data,
      );
      return response;
    } catch (error) {
      throw new Error("Error al crear la actividad parroquial");
    }
  }

  async createActividadOnlyWithName(data: any): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.post(
        "/actividadParroquialNombre",
        data,
      );
      return response;
    } catch (error) {
      throw new Error("Error al crear la actividad parroquial");
    }
  }

  async deleteActividad(id: number): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.delete(
        "/actividadParroquial/delete/" + id,
      );
      return response;
    } catch (error) {
      throw new Error("Error al eliminar la actividad parroquial");
    }
  }

  async getActividadNombres(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/actividadParroquialNombre/allActivitiesIdAndName",
      );
      return response;
    } catch (error) {
      console.log("Error al obtener la actividad parroquial");
    }
  }

  async getLiturgiasWithOnlyIdAndType(): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/liturgia/allLiturgiasWithIdAndType",
      );
      return response;
    } catch (error) {
      console.log("Error al obtener los ritos");
    }
  }

  async findActividadByActivityNameIdAndGroupId(
    activityNameId: number,
    groupId: number,
  ): Promise<any> {
    try {
      const response: AxiosResponse<any> = await this.get(
        "/actividadParroquial/findActivityParroquialByActivityNameIdAndGroupId/" +
          activityNameId +
          "/" +
          groupId,
      );
      return response;
    } catch (error) {
      console.log("Error al obtener las actividades parroquiales");
    }
  }
}

const actividadParroquialService = new ActividadParroquialService();

export default actividadParroquialService;
