import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useEventContext } from "../../contexts/EventContext";

export default function AddEventForm() {
  //@ts-ignore
  const { saveEvent, handleClose, selectedEvent } = useEventContext();

  const [title, setTitle] = React.useState("");
  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);
  const [repeatWeeks, setRepeatWeeks] = React.useState(1); // Opción de repetir cada x semanas
  const [selectedDays, setSelectedDays] = React.useState([]); // Días seleccionados de la semana
  const [value, setValue] = React.useState("#ffffff");

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  const daysOfWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const handleDayChange = (event: any) => {
    setSelectedDays(event.target.value);
  };

  const submit = async () => {
    try {
      const sent = await saveEvent({
        title,
        start,
        end,
        repeatWeeks,
        selectedDays,
      });
      if (sent.success) {
        setTitle("");
        setStart(null);
        setEnd(null);
        setRepeatWeeks(1);
        setSelectedDays([]);
        handleClose();
      }
    } catch (e) {
      alert(e);
    }
  };

  React.useEffect(() => {
    if (selectedEvent) {
      setTitle(selectedEvent.title || "");
      setStart(selectedEvent.start || null);
      setEnd(selectedEvent.end || null);
      setRepeatWeeks(selectedEvent.repeatWeeks || 1);
      setSelectedDays(selectedEvent.selectedDays || []);
    }
  }, [selectedEvent]);

  return (
    <div>
      <DialogTitle>Agregar Nueva Fecha</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Crea la fecha que estará disponible la experiencia.
        </DialogContentText>
        <Grid container spacing={2} style={{ paddingTop: "3%" }}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre"
              type="email"
              fullWidth
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiColorInput format="hex" value={value} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <div className="container">
              <div className="material-textfield">
                <input
                  //@ts-ignore
                  value={start}
                  className="calendar_input"
                  //@ts-ignore

                  onChange={(e) => setStart(e.target.value)}
                  type="datetime-local"
                />
                <label className="calendar_label">Comienzo</label>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="calendar_label container">
              <div className="material-textfield">
                <input
                  className="calendar_input"
                  //@ts-ignore

                  value={end}
                  //@ts-ignore

                  onChange={(e) => setEnd(e.target.value)}
                  type="datetime-local"
                />
                <label className="calendar_label">Fin</label>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              multiple
              value={selectedDays}
              onChange={handleDayChange}
              inputProps={{
                name: "days",
                id: "days-select",
              }}
              variant="outlined"
              label="Días de la semana"
            >
              {daysOfWeek.map((day, index) => (
                <MenuItem key={index} value={index}>
                  {day}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              id="repeat-weeks"
              label="Repetir cada x semanas"
              type="number"
              fullWidth
              variant="outlined"
              value={repeatWeeks}
              //@ts-ignore

              onChange={(e) => setRepeatWeeks(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={submit}>Guardar</Button>
      </DialogActions>
    </div>
  );
}
