import { CSSProperties } from "react";

export const stylesCreateFeriado: Record<string, CSSProperties> = {
  box1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    height: "100%",
    gap: "2em",
  },
  box2: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "2em",
  },
  box3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  h2: {
    color: "black",
    textAlign: "center",
  },
  divForm: {
    backgroundColor: "white",
    padding: "4%",
    width: "60%",
    height: "100%",
    marginTop: "2em",
  },
  texfields: {
    backgroundColor: "white",
  },
  typography: {
    color: "#264B99",
    marginTop: "0.5em",
    fontSize: "1.1em",
  },
  gridFormControl: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  formControlRadios: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "12em",
    width: "100%",
    alignItems: "center",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  staticDatePicker: {
    backgroundColor: "white",
    borderRadius: "20px",
    margin: "1em",
  },
  formRadioGroup: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
  },
  formRadioGroupItem: {
    flex: 1,
  },
  gridButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1%",
  },
  gridDates: {
    display: "flex",
    flexDirection: "row",
    gap: "2em",
    marginTop: "1em",
  },
  texfieldComments: {
    borderRadius: "5px",
    marginTop: "2em",
  },
};
