import axios, { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

class LocacionesService extends GenericService {
  constructor() {
    super();
  }

  async getCountries(): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get(`/country`);
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las ubicaciones");
      return [];
    }
  }

  async findProvincesByCountryId(countryId: string): Promise<any[]> {
    if (countryId === undefined || countryId === null || countryId === "") {
      return [];
    }
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/province/findProvinceByCountryId/${countryId}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las ubicaciones");
      return [];
    }
  }

  async findPartsByProvinceId(provinceId: string): Promise<any[]> {
    if (provinceId === undefined || provinceId === null || provinceId === "") {
      return [];
    }
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/part/findPartsByProvinceId/${provinceId}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las ubicaciones");
      return [];
    }
  }

  async findLocalitiesByPartId(partId: string): Promise<any[]> {
    if (partId === undefined || partId === null || partId === "") {
      return [];
    }
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/locality/findLocalitiesByPartId/${partId}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las ubicaciones");
      return [];
    }
  }

  async findIglesiasAndLocalitiesByPartId(partId: string): Promise<any[]> {
    if (partId === undefined || partId === null || partId === "") {
      return [];
    }
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/locality/findIglesiasAndLocalitiesByPartId/${partId}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las ubicaciones");
      return [];
    }
  }

  async findPartWithLocalities(partId: string): Promise<any[]> {
    if (partId === undefined || partId === null || partId === "") {
      return [];
    }
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/part/findPart/${partId}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las ubicaciones");
      return [];
    }
  }

  async getLocalities(): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get(`/locality`);
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las localidades");
      return [];
    }
  }

  async findProvincePartAndCountryByLocalityId(
    localityId: string,
  ): Promise<any> {
    if (!localityId) {
      return [];
    }
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/locality/findProvincePartAndCountryByLocalityId/${localityId}`,
      );
      //@ts-ignore
      return response;
    } catch (error) {
      console.log("Error al obtener las ubicaciones");
      return [];
    }
  }
}

export const locacionesService = new LocacionesService();
