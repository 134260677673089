import { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export enum EventState {
  Pending = "pending",
  Approved = "approved",
  Rejected = "rejected",
  Completed = "completed",
  Incompleted = "incompleted",
}

export class EventosService extends GenericService {
  constructor() {
    super();
  }

  async getEvents(eventType: string): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/event/events/${eventType}`,
      );
      return response as any;
    } catch (error) {
      console.log(`Error al obtener los eventos de la iglesia`);
      return [];
    }
  }

  async getEventsByTurnoId(
    turnoId: number,
    page: number,
    pageSize: number,
    filter?: string,
  ): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/event/eventsByTurnoId/${turnoId}/${page}/${pageSize}?${filter}`,
      );
      return response as any;
    } catch (error) {
      console.log(`Error al obtener los eventos de la iglesia`);
      return [];
    }
  }

  async getRolsSacramentosByEventId(eventId: number): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/event/rolsSacramentosByEventId/${eventId}`,
      );
      return response as any;
    } catch (error) {
      console.log(`Error al obtener los eventos de la iglesia`);
      return [];
    }
  }

  async updateEventState(id: number, event: any, state?: EventState) {
    const eventState =
      state ||
      (event.state == EventState.Pending
        ? EventState.Approved
        : EventState.Pending);
    try {
      const response: AxiosResponse<any> = await this.post(
        `/event/updateEventState/${id}`,
        { eventState },
      );
      return response.data;
    } catch (error) {
      console.log(`Error al actualizar el estado con evento con ID ${id}`);
    }
  }

  async completeEventData(
    formData: any,
    eventId: number | undefined,
  ): Promise<any[]> {
    try {
      const response: AxiosResponse<any[]> = await this.post(
        `/event/completeEventData/${eventId}`,
        { formData },
      );
      return response as any;
    } catch (error) {
      console.log(`Error al obtener los eventos de la iglesia`);
      return [];
    }
  }

  async deleteEvent(id: number) {
    try {
      //@ts-ignore
      const response: AxiosResponse<any> = await this.delete(`/event/${id}`);
      return response;
    } catch (error) {
      console.log(`Error al actualizar el estado con evento con ID ${id}`);
    }
  }

  async sendEmail(eventId?: number | null) {
    try {
      const response: AxiosResponse<any[]> = await this.get(
        `/event/sendEmail/${eventId}`,
      );
      return response as any;
    } catch (error) {
      return [];
    }
  }
}

const eventosService = new EventosService();

export default eventosService;
