import { SacramentoEnum } from "../enums/SacramentoEnum";
import ObservableService, {
  ObservableEvents,
} from "../services/ObservableService";
import calendarioService from "../services/calendario";
import moment from "moment-timezone";

// Interfaz para el frontend
export interface CreateEvent {
  startTime: Date;
  endTime: Date;
  startHour: number;
  endHour: number;
  turnoId?: number;
  specificationId: number;
  slots: number;
  type: string;
}

/**
 * SchedulerUtils
 */
class SchedulerUtils {
  /**
   * Handle create event
   * @param data
   */
  handleCreateEvent = async (data: CreateEvent) => {
    let event: any = await calendarioService.create(data);
    //TODO add validator
    ObservableService.notifyListeners(ObservableEvents.SACRAMENTO, event.id);
    return event;
  };

  /**
   * Handle action
   * @param dateSelected
   */
  async handleAction(
    dateSelected: any,
    eventType: SacramentoEnum,
    timeZone: string,
  ) {
    const { day, start, end, turnoId, specificationId, limit, count } =
      dateSelected.current as any;
    if (!!day && !!start && !!end) {
      const startTime = moment.tz(day, "UTC").startOf("day").toDate();
      const endTime = moment.tz(day, "UTC").startOf("day").toDate();

      const createEvent = await this.handleCreateEvent({
        startTime: startTime,
        endTime: endTime,
        startHour: start,
        endHour: end,
        turnoId: turnoId,
        specificationId: specificationId,
        slots: limit,
        type: eventType,
      });

      return createEvent;
    }
  }
}

export default new SchedulerUtils();
