export enum CreateFeriadoEnum {
  CREAR_FERIADO = "Crear Feriado",
  ACTUALIZAR_FERIADO = "Actualizar Feriado",
  MOTIVO_FERIADO = "Motivo del feriado",
  ALCANCE_FERIADO = "Alcance del feriado",
  LUGAR = "Lugar",
  DIA_CELEBRACION = "Día de celebración",
  FECHA = "Fecha",
  TRANSFER_TO = "Transferir a",
  TRANSFER_TO_OPTIONAL = "Transferir a (opcional)",
  COMENTARIOS = "Comentarios",
  CATEDRAL_DE_LA_PLATA = "Catedral de La Plata",
  FERIADO_SUCESS = "Se creo el feriado correctamente.",
  FERIADO_ERROR = "No se pudo crear el feriado.",
  FERIADO_ACTUALIZADO = "Feriado actualizado correctamente.",
  FERIADO_NO_ACTUALIZADO = "No se pudo actualizar el feriado.",
  FERIADO_APROBADO = "Feriado aprobado con éxito.",
  FERIADO_NO_APROBADO = "Error al aprobar el feriado.",
  BACK = "Volver",
}

export enum Alcance_Feriado {
  NACIONAL = "Nacional",
  PROVINCIAL = "Provincial",
  LOCAL = "Local",
}

export enum Lugar {
  PAIS = "País",
  PROVINCIA = "Provincia",
  LOCALIDAD = "Localidad",
  PARTIDO_DEPARTAMENTO = "Partido/Departamento",
  CODIGO_POSTAL = "Código Postal",
}

export enum FiltrosEnum {
  SCOPE = "scope",
  STATE = "state",
  PLACE = "place",
  DATE = "date",
  ALCANCE = "Alcance",
  ESTADO = "Estado",
  LUGAR = "Lugar",
  FECHA = "Fecha",
}

export enum ListFeriadosEnum {
  ERROR_APROBAR_FERIADO = "Error al aprobar el feriado:",
  CONFIRM_DELETE = "¿Estás seguro que deseas eliminar esta programación?",
  FERIADO_ELIMINADO = "Se eliminó el feriado correctamente",
}
