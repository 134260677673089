import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import BautismoPage from "../pages/bautismo/BautismoPage";
import WeddingPage from "../pages/casamiento/CasamientoPage";
import ComunionPage from "../pages/comunion/ComunionPage";
import ConfirmationPage from "../pages/confirmation/ConfirmacionPage";
import Panel from "../pages/panel/PanelPage";
import { RoutesEnum } from "../utils/RoutesEnum";
import PrivateRoute from "../components/privateRoute";
import SuperAdminRoute from "./superAdminRoute";
import CalendarPage from "../pages/calendar/CalendarPage";
import Navbar from "../components/navbar/panel";
import ConfigurarTurnos from "../pages/turnos/ConfigurarTurnos/ConfigurarTurnos";
import Certifications from "../pages/certifications/CertificationsPage";
import { CertificationType } from "../utils/cerficates/bautismoEnum";
import RolesList from "../pages/roles/RolesPage";
import ListadoDeTurnosProgramados from "../pages/turnos/ListadoDeTurnosProgramados";
import ListadoDeTurnos from "../pages/turnos/ListadoDeTurnos";
import SectoresList from "../pages/sectores/SectoresList";
import ListadoDeTurnosPendientes from "../pages/turnos/ListadoDeTurnosPendientes/ListadoDeTurnosPendientes";
import EventList from "../pages/turnos/EventList/EventList";
import CreateSector from "../pages/sectores/CreateSector";
import RegisterPage from "../pages/register/RegistePage";
import AdminUsersItem from "../pages/adminUsers/AdminUsersItem";
import SearchPeople from "../pages/peoples/SearchPeople";
import PeopleData from "../pages/peoples/PeopleData";
import ParentData from "../pages/peoples/ParentData";
import ProfilePage from "../pages/Profile/ProfilePage";
import IglesiaDetailPage from "../pages/iglesia-configuration/IglesiaDetailPage";
import ResetPasswordPage from "../pages/resetPassword/ResetPasswordPage";
import EditSector from "../pages/turnos/EditSector";
import RolesIglesiaPage from "../pages/roles/RolesIglesiaPage";
import AddRolePage from "../pages/AddRolePage";
import SacerdoteShadowList from "../pages/sacerdoteShadow/SacerdoteShadowList";
import CreateUserSacerdote from "../pages/sacerdoteShadow/CreateUserSacerdote";
import CreateFeriado from "../pages/feriados/CreateFeriado/CreateFeriado";
import ListadoDeFeriadosProgramados from "../pages/feriados/ListadoFeriados/ListadoFeriados";
import CreateTurno from "../pages/turnosV2/Createturno/CreateTurno";
import CreateTurnoAP from "../pages/turnosV2/Createturno/CreateTurnoAP";
import ListadoDeMisasProgramadas from "../pages/misas/ListadoDeMisasProgramadas";
import MisasList from "../pages/misas/MisasList";
import CreateActividadParroquial from "../pages/actividadesParroquiales/createActividadParroquial/CreateActividadParroquial";
import ListadoDeActividadesParroquialesPendientes from "../pages/actividadesParroquiales/ListadoActividadesParroquiales/ListadoActividadesParroquiales";
import ActividadesParroquialesList from "../pages/actividadesParroquiales/ListadoActividadesParroquiales/ActividadesParroquialesList";
import CreateParticipante from "../pages/actividadesParroquiales/PeopleActividadParroquial/CreateParticipante";
import ShowParticipantesActividadParroquial from "../pages/actividadesParroquiales/PeopleActividadParroquial/ShowPeopleActividadParroquial";
import EnabledUserForUpload from "../pages/actividadesParroquiales/EnabledUserForUpload/EnabledUserForUpload";
import { SacramentoEnum } from "../enums/SacramentoEnum";
import AddPeopleToTurn from "../pages/actividadesParroquiales/AddPeopleToTurn/AddPeopleToTurn";
import CoordinatorListTurns from "../pages/actividadesParroquiales/AddPeopleToTurn/CoordinatorListTurns";
import AddGroupPeopleToTurn from "../pages/actividadesParroquiales/AddPeopleToTurn/AddGroupPeopleToTurn";

/**
 * Private navigator
 * @returns
 */
const PrivateNavigator = (): JSX.Element => {
  /**
   * Render the private route
   * @param child
   * @returns
   */
  const renderPrivate = (child: JSX.Element): JSX.Element => {
    return <PrivateRoute redirectTo={RoutesEnum.LOGIN}>{child}</PrivateRoute>;
  };

  /**
   * Render the super admin route
   * @param child
   * @returns
   */
  const renderSuperAdmin = (child: JSX.Element): JSX.Element => {
    return (
      <SuperAdminRoute redirectTo={RoutesEnum.LOGIN}>{child}</SuperAdminRoute>
    );
  };

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path={RoutesEnum.PANEL} element={renderPrivate(<Panel />)} />
        <Route
          path={RoutesEnum.CALENDAR_V2}
          element={renderPrivate(<CalendarPage />)}
        />
        <Route
          path={RoutesEnum.BAUTISMO}
          element={renderPrivate(<BautismoPage />)}
        />
        <Route
          path={RoutesEnum.CERTIFICACION_BAUTISMO}
          element={renderPrivate(
            <Certifications type={CertificationType.BAUTISMO} />,
          )}
        />
        <Route
          path={RoutesEnum.CERTIFICACION_CONFIRMACION}
          element={renderPrivate(
            <Certifications type={CertificationType.CONFIRMACION} />,
          )}
        />
        <Route
          path={RoutesEnum.CERTIFICACION_CASAMIENTO}
          element={renderPrivate(
            <Certifications type={CertificationType.CASAMIENTO} />,
          )}
        />
        <Route
          path={RoutesEnum.CERTIFICACION_COMUNION}
          element={renderPrivate(
            <Certifications type={CertificationType.COMUNION} />,
          )}
        />
        <Route
          path={RoutesEnum.CONFIRMACIONES}
          element={renderPrivate(<ConfirmationPage />)}
        />
        <Route
          path={RoutesEnum.CASAMIENTOS}
          element={renderPrivate(<WeddingPage />)}
        />
        <Route
          path={RoutesEnum.COMUNIONES}
          element={renderPrivate(<ComunionPage />)}
        />
        <Route
          path={RoutesEnum.ADMIN_USERS}
          element={renderSuperAdmin(<RolesList isAdmin={true} />)}
        />
        <Route
          path={RoutesEnum.ADMIN_USERS_ITEM}
          element={renderSuperAdmin(<AdminUsersItem />)}
        />
        <Route
          path={RoutesEnum.TURNOS}
          element={renderPrivate(<ListadoDeTurnos />)}
        />
        <Route
          path={RoutesEnum.TURNOS_CONFIGURATION}
          element={renderPrivate(<ListadoDeTurnosProgramados />)}
        />
        <Route
          path={RoutesEnum.SECTORES}
          element={renderPrivate(<SectoresList />)}
        />
        <Route
          path={RoutesEnum.IGLESIA_CONFIGURATION}
          element={renderPrivate(<IglesiaDetailPage />)}
        />
        <Route
          path={RoutesEnum.TURNOS_CREATE}
          element={renderPrivate(<CreateTurno />)}
        />
        <Route
          path={RoutesEnum.TURNOS_EDIT}
          element={renderPrivate(<CreateTurno />)}
        />
        <Route
          path={RoutesEnum.TURNOS_PENDIENTES}
          element={renderPrivate(<ListadoDeTurnosPendientes />)}
        />
        <Route
          path={RoutesEnum.MISAS_PROGRAMADAS}
          element={renderPrivate(<ListadoDeMisasProgramadas />)}
        />
        <Route
          path={RoutesEnum.LISTADO_MISAS}
          element={renderPrivate(<MisasList />)}
        />
        <Route
          path={RoutesEnum.EVENT_LIST}
          element={renderPrivate(<EventList />)}
        />
        <Route
          path={RoutesEnum.ROLES}
          element={renderPrivate(<RolesList isAdmin={false} />)}
        />
        <Route
          path={RoutesEnum.ROLES_IGLESIA}
          element={renderPrivate(<RolesIglesiaPage isAdmin={false} />)}
        />
        <Route
          path={RoutesEnum.ADD_ROLE_IGLESIA}
          element={renderPrivate(<AddRolePage isAdmin={false} />)}
        />
        <Route
          path={RoutesEnum.PROFILE}
          element={renderPrivate(<ProfilePage />)}
        />
        <Route
          path={RoutesEnum.SECTORES_CREATE}
          element={renderPrivate(<CreateSector />)}
        />
        <Route
          path={RoutesEnum.SECTORES_EDIT}
          element={renderPrivate(<EditSector />)}
        />
        <Route
          path={RoutesEnum.REGISTER}
          element={renderPrivate(<RegisterPage />)}
        />{" "}
        {/* TODO change renderPrivate for renderSuperAdmin*/}
        <Route
          path={RoutesEnum.PERSONAS}
          element={renderPrivate(<SearchPeople />)}
        />
        <Route
          path={RoutesEnum.PERSONAS_DATA}
          element={renderPrivate(<PeopleData />)}
        />
        <Route
          path={RoutesEnum.PERSONAS_DATA_FATHER}
          element={renderPrivate(<ParentData />)}
        />
        <Route
          path={RoutesEnum.PERSONAS_DATA_MOTHER}
          element={renderPrivate(<ParentData />)}
        />
        <Route
          path={RoutesEnum.RESET_PASSWORD}
          element={<ResetPasswordPage />}
        />
        <Route path={RoutesEnum.SET_PASSWORD} element={<ResetPasswordPage />} />
        <Route
          path={RoutesEnum.SACERDOTE_SHADOW_LIST}
          element={renderPrivate(<SacerdoteShadowList />)}
        />
        <Route
          path={RoutesEnum.SACERDOTE_SHADOW_CREATE}
          element={renderPrivate(<CreateUserSacerdote />)}
        />
        <Route
          path={RoutesEnum.FERIADOS}
          element={renderPrivate(<ListadoDeFeriadosProgramados />)}
        />
        <Route
          path={RoutesEnum.FERIADOS_CREATE}
          element={renderPrivate(<CreateFeriado />)}
        />
        <Route
          path={RoutesEnum.FERIADOS_EDIT}
          element={renderPrivate(<CreateFeriado />)}
        />
        <Route
          path={RoutesEnum.ACTIVIDAD_PARROQUIAL_CREATE}
          element={renderPrivate(<CreateActividadParroquial />)}
        />
        <Route
          path={RoutesEnum.ACTIVIDAD_PARROQUIAL_CREATE_EVENT}
          element={renderPrivate(
            <CreateTurnoAP isActividadParroquial={true} />,
          )}
        />
        <Route
          path={RoutesEnum.ACTIVIDADES_PARROQUIALES_PENDIENTES}
          element={renderPrivate(
            <ListadoDeActividadesParroquialesPendientes />,
          )}
        />
        <Route
          path={RoutesEnum.LISTADO_ACTIVIDADES_PARROQUIALES}
          element={renderPrivate(<ActividadesParroquialesList />)}
        />
        <Route
          path={RoutesEnum.LISTADO_PARTICIPANTES_ACTIVIDAD_PARROQUIAL}
          element={renderPrivate(<ShowParticipantesActividadParroquial />)}
        />
        <Route
          path={RoutesEnum.CREATE_PARTICIPANTES_ACTIVIDAD_PARROQUIAL}
          element={renderPrivate(<CreateParticipante />)}
        />
        <Route
          path={RoutesEnum.ENABLED_USER_FOR_UPLOAD_CONFIRMATION}
          element={renderPrivate(
            <EnabledUserForUpload turnoType={SacramentoEnum.Confirmacion} />,
          )}
        />
        <Route
          path={RoutesEnum.ENABLED_USER_FOR_UPLOAD_COMUNION}
          element={renderPrivate(
            <EnabledUserForUpload turnoType={SacramentoEnum.Comunion} />,
          )}
        />
        <Route
          path={RoutesEnum.EDIT_PARTICIPANTES_ACTIVIDAD_PARROQUIAL}
          element={renderPrivate(<CreateParticipante />)}
        />
        <Route
          path={RoutesEnum.ADD_PARTICIPANTS_TO_TURN}
          element={renderPrivate(<AddPeopleToTurn />)}
        />
        <Route
          path={RoutesEnum.ADD_GROUP_PEOPLE_TO_TURN}
          element={renderPrivate(<AddGroupPeopleToTurn />)}
        />
        <Route
          path={RoutesEnum.SHOW_TURNS_ENABLED_BY_COORDINATOR}
          element={renderPrivate(<CoordinatorListTurns />)}
        />
      </Routes>
    </Router>
  );
};

export default PrivateNavigator;
