import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AddGroupPeopleToTurnTexts,
  AddToPeopleStrings,
  CreateActividadParroquialEnum,
} from "../../../enums/pages/CreateActividadParroquialEnum";
import { FormTextSend } from "../../../enums/components/componentsEnum";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Paper from "@mui/material/Paper";
import { AddPeopleToTurnStyles } from "./AddPeopleToTurnStyles";
import participanteActividadParroquialService from "../../../services/ParticipanteActividadParroquial/ParticipanteActividadParroquialService";
import { SacramentoEnum } from "../../../enums/SacramentoEnum";
import turnosHabilitadosService from "../../../services/turnosHabilitados/TurnosHabilitadosService";
import { queryClient } from "../../../App";
import { addNotification } from "../../../utils/notifications";
import turnoExpecificationService from "../../../services/turnosExpecification";

const AddGroupPeopleToTurn: React.FC = () => {
  const navigate = useNavigate();
  const { idExpecification, idSimpleTurn, type, coordinatorId } = useParams<{
    idExpecification: string;
    idSimpleTurn: string;
    type: SacramentoEnum;
    coordinatorId: any;
  }>();
  const [participantes, setParticipantes] = useState([]);

  useEffect(() => {
    turnoExpecificationService
      .getEncuentroByActivityId(Number(idExpecification))
      .then((response: any) => {
        setParticipantes(response[0].participantes);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formattedData = {
      participantes: participantes.map((participante: any) => {
        return { id: participante.participante.id };
      }),
      idExpecification: Number(idExpecification),
      type: type,
      coordinatorId: coordinatorId,
    };
    try {
      await turnosHabilitadosService.addGroupPeopleToTurn(
        formattedData,
        idSimpleTurn,
      );
      addNotification(
        queryClient,
        "success",
        AddGroupPeopleToTurnTexts.GRUPO_AÑADIDO_CORRECTAMENTE,
      );
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.errorCode ||
        AddGroupPeopleToTurnTexts.ERROR_AÑADIR_GRUPO;
      addNotification(queryClient, "error", errorMessage);
    }
  };

  return (
    <Box sx={AddPeopleToTurnStyles.divForm}>
      <form onSubmit={handleSubmit}>
        <Box sx={AddPeopleToTurnStyles.box2}>
          <IconButton onClick={handleGoBack} sx={{}}>
            <ArrowBackIcon />
          </IconButton>

          <Box sx={AddPeopleToTurnStyles.titleContainer}>
            <Typography sx={AddPeopleToTurnStyles.typographyTitle}>
              {AddToPeopleStrings.ADD_GROUP_TO_TURN}
            </Typography>
          </Box>
        </Box>

        <Grid item xs={11.5} sx={AddPeopleToTurnStyles.gridContainerTable}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participantes?.map((participante: any) => (
                  <TableRow
                    key={participante.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {participante.participante.name}
                    </TableCell>
                    <TableCell>{participante.participante.surname}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid sx={AddPeopleToTurnStyles.gridButton}>
          <Button
            type={FormTextSend.SUBMIT}
            variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
            color={CreateActividadParroquialEnum.PRIMARY}
            style={AddPeopleToTurnStyles.buttonSave}
          >
            {CreateActividadParroquialEnum.ADD_GROUP}
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default AddGroupPeopleToTurn;
