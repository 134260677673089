import { Avatar, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { stylesCreateTurno } from "../../pages/turnosV2/Createturno/stylesCreateTurno";
import { CreateTurnoEnum, ValuesEnum } from "../../enums/pages/CreateTurnoEnum";
import moment, { Moment } from "moment";
import { convertUTCToLocal } from "../datesUtils/dateUtils";
import { Scope } from "../../enums/ScopeEnum";

export interface TurnoState {
  id?: string;
  type: string;
  criteryFeriados: string;
  periodicity: string;
  enabledDays: string[];
  startTime: Date | null;
  endTimeRepetition: Date | null;
  startHour: string;
  endHour: string;
  sectorId: string;
  sacerdoteId: string;
  week: string;
  day: string;
  slots: string | null;
  startHourSpecial?: string | null;
  endHourSpecial?: string | null;
  actividadParroquialId?: string;
  groupParroquialId?: string;
  scope: Scope | null;
  activityId: string;
  parentId?: string;
}

export function initializeTurno() {
  return {
    startTime: null,
    endTimeRepetition: null,
    type: "",
    criteryFeriados: "",
    periodicity: "",
    enabledDays: [] as string[],
    startHour: "",
    endHour: "",
    sectorId: "",
    sacerdoteId: "",
    week: "",
    day: "",
    slots: null,
    startHourSpecial: null,
    endHourSpecial: null,
    actividadParroquialId: "",
    groupParroquialId: "",
    scope: null,
    activityId: "",
  };
}

export const dias = ["L", "M", "X", "J", "V", "S", "D"];

export const opcionesTipo = [
  { value: "Bautismo", label: "Bautismo" },
  { value: "Casamiento", label: "Casamiento" },
  { value: "Comunion", label: "Comunion" },
  { value: "Confirmacion", label: "Confirmacion" },
  { value: "Misa", label: "Misa" },
];

export const opcionesSemana = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const opcionesDia = [
  { value: "Lunes", label: "Lunes" },
  { value: "Martes", label: "Martes" },
  { value: "Miercoles", label: "Miercoles" },
  { value: "Jueves", label: "Jueves" },
  { value: "Viernes", label: "Viernes" },
  { value: "Sabado", label: "Sabado" },
  { value: "Domingo", label: "Domingo" },
];

export const opcionesDiaNumericos = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" },
];

export const handleSelectDay = (
  day: string,
  setDiasSeleccionados: Function,
  setTurno: Function,
) => {
  setDiasSeleccionados((prevDays: any) => {
    const isDaySelected = prevDays.includes(day);
    let newDiasSeleccionados: string[] = [];
    if (isDaySelected) {
      // Deselect: remove the day from the array
      newDiasSeleccionados = prevDays.filter((d: any) => d !== day);
    } else {
      // Select: add the day to the array
      newDiasSeleccionados = [...prevDays, day];
    }

    setTurno((prevTurno: any) => ({
      ...prevTurno,
      enabledDays: newDiasSeleccionados,
      day: "",
      week: "",
    }));

    return newDiasSeleccionados;
  });
};

export const handleSacerdoteChange = (event: any, setTurno: Function) => {
  setTurno((prevTurno: any) => ({
    ...prevTurno,
    sacerdoteId: event.target.value,
  }));
};

export const handleTipoChange = (
  event: SelectChangeEvent<string>,
  setTurno: Function,
) => {
  setTurno((prevTurno: any) => ({
    ...prevTurno,
    type: event.target.value,
  }));
};

export const handleSemanaChange = (
  event: SelectChangeEvent<string>,
  setTurno: Function,
) => {
  setTurno((prevTurno: any) => ({
    ...prevTurno,
    week: event.target.value,
    day: "",
  }));
};

export const handleDiaChange = (
  setDiasSeleccionados: Function,
  event: SelectChangeEvent<string>,
  setTurno: Function,
) => {
  setDiasSeleccionados([]);
  setTurno((prevTurno: any) => ({
    ...prevTurno,
    day: event.target.value,
    week: "",
    enabledDays: [],
  }));
};

export const handleSectorChange = (
  event: any,
  setTurno: Function,
  turno: any,
) => {
  setTurno({ ...turno, sectorId: event.target.value });
};

export const handleStartTimeChange = (
  date: string,
  setSelectedDate: Function,
  setTurno: Function,
  turno: any,
) => {
  const momentDate = moment(date, "YYYY-MM-DD");
  setSelectedDate(momentDate);
  //@ts-ignore
  setTurno({ ...turno, startTime: momentDate.format("YYYY-MM-DD") });
};

export const handleEndTimeChange = (
  date: string,
  setSelectedDate: Function,
  setTurno: Function,
  turno: any,
) => {
  const momentDate = moment(date, "YYYY-MM-DD");
  setSelectedDate(momentDate);
  //@ts-ignore
  setTurno({ ...turno, endTimeRepetition: momentDate.format("YYYY-MM-DD") });
};

export const handleDayProperties = (
  day: any,
  turno: any,
  selectedDate: any,
) => {
  const dayFormatted = day.$isDayjsObject
    ? day.format("YYYY-MM-DD")
    : moment(day, "YYYY-MM-DD").format("YYYY-MM-DD");
  const isStartTime =
    turno.startTime &&
    moment(turno.startTime).format("YYYY-MM-DD") === dayFormatted;
  const isEndTime =
    turno.endTimeRepetition &&
    moment(turno.endTimeRepetition).format("YYYY-MM-DD") === dayFormatted;

  const style = {
    ...(isStartTime ? { backgroundColor: "#add8e6" } : {}),
    ...(isEndTime ? { backgroundColor: "#92cadb" } : {}),
  };

  return {
    style,
    "data-selected-day": selectedDate ? moment(selectedDate).format("DD") : "",
  };
};

type PeriodicityKey = "diaria" | "mensual" | "por_fecha";

export const formatPeriodicity = (periodicity: PeriodicityKey | string) => {
  const periodicityMap: { [key in PeriodicityKey]: string } = {
    diaria: "Diaria",
    mensual: "Mensual",
    por_fecha: "Por fecha",
  };

  return periodicityMap[periodicity as PeriodicityKey] || periodicity;
};

enum CriteryFeriados {
  HorarioEspecial = "horario_especial",
  HorarioNormal = "horario_normal",
  SinActividad = "sin_actividad",
}

export const formatCriteryFeriados = (critery: CriteryFeriados | string) => {
  const criteryMap: { [key in CriteryFeriados]: string } = {
    horario_especial: "Horario Especial",
    horario_normal: "Horario Normal",
    sin_actividad: "Sin Actividad",
  };

  return criteryMap[critery as CriteryFeriados] || critery;
};
