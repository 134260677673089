import React from "react";
import "../../index.css";
// Components
import ConfirmationModal from "../modals/ConfirmationModal";
// Contexts
import { useEventContext } from "../../contexts/EventContext";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { convertUTCToLocal } from "../../../../utils/datesUtils/dateUtils";
import moment from "moment";

export default function ShowEventForm() {
  //@ts-ignore

  const { handleClose, selectedEvent, setFormType, removeEvent } =
    useEventContext();

  const navigate = useNavigate();

  return (
    <div>
      <DialogTitle>Evento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="email"
              fullWidth
              variant="outlined"
              value={selectedEvent ? selectedEvent.title : null}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              //@ts-ignore

              dense
              fullWidth
              variant="outlined"
              label="Description"
              placeholder="Enter event description"
              multiline
              rows={2}
              maxRows={3}
              value={selectedEvent ? selectedEvent.description : null}
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <div className="container">
              <div className="material-textfield">
                <label>
                  Hora de inicio{" "}
                  {convertUTCToLocal(
                    moment(selectedEvent.start).format("HH:mm"),
                  )}{" "}
                  hs
                </label>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="container">
              <div className="material-textfield">
                <label>
                  Hora de fin:{" "}
                  {convertUTCToLocal(moment(selectedEvent.end).format("HH:mm"))}{" "}
                  hs
                </label>
              </div>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ConfirmationModal action="Delete Event" handleSub={removeEvent} />
        <Button onClick={handleClose}>Cerrar</Button>
        {/* <Button onClick={() => {
          if (selectedEvent.title === "Feriado") {
            navigate(`/panel/feriados/edit/${selectedEvent.turnoId}`, { state: { feriadoId: selectedEvent.turnoId } });
          } else {
            navigate(`/panel/turnos/edit/${selectedEvent.turnoId}`, { state: { turnoId: selectedEvent.turnoId } });
          }
        }}>Editar</Button> */}
        {selectedEvent.count > 0 &&
          selectedEvent.title !== "Feriado" &&
          !!selectedEvent.turnoSimpleId && (
            <Button
              onClick={() =>
                navigate(`/panel/event-list/${selectedEvent.turnoSimpleId}`, {
                  state: { turnoId: selectedEvent.turnoSimpleId },
                })
              }
            >
              Ver turnos
            </Button>
          )}
      </DialogActions>
    </div>
  );
}
