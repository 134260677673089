export const AddPeopleToTurnStyles = {
  gridButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginTop: "4%",
  },
  buttonSave: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  divForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: "4% 4% 4% 3%",
    width: "60%",
    paddingLeft: "5em",
    height: "100%",
    margin: "0 auto",
  },
  typographyTitle: {
    color: "#264B99",
    fontSize: "1.4em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  box3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  typography: {
    color: "#264B99",
    marginTop: "0.5em",
    fontSize: "1.1em",
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  gridContainerTable: {
    marginTop: "2em",
  },
};
