import { CSSProperties } from "react";
import { theme } from "../../theme";

export const styles = {
  box1: {
    width: "50%",
    paddingTop: "3em",
    paddingBottom: "3em",
  },
  box2: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
  },
  renderContentGrid: {},
  alert: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "15px",
  },
  errorStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    fontSize: "12px",
    color: "red",
  },
  errorStyleOk: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    fontSize: "12px",
    color: "green",
  },
  button: {
    marginTop: "10px",
  },
  gridButton: {
    textAlign: "center",
  },
  h2: {
    color: "black",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    margin: "auto",
  },
  typography1: {
    color: theme.font.primary,
    fontSize: "18px",
    fontWeight: 600,
    marginLeft: "15px",
  },
  typography2: {
    fontSize: "20px",
    fontWeight: 500,
    color: "#31876C",
    marginBottom: "20px",
  },
  form: {
    marginTop: "0px",
  },
  typography3: {
    margin: "15px 0",
    fontWeight: 500,
    color: "#31876C",
  },
  div: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "5%",
  },
  boxPrincipal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  typography6: {
    fontWeight: 500,
    color: "#31876C",
    marginBottom: "15px",
  },
  grid3: {
    marginTop: "15px",
  },
  typographyPais: {
    fontWeight: 500,
    color: "#31876C",
  },
  gridRenderFilters: {
    boxShadow: "0px 0px 5px .1px #3176B0",
    borderRadius: "15px",
    margin: "0.5%",
    padding: "1%",
  },
  gridButtonRender: {
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
    color: "black",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
  },
  boxReturn1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1.5em",
  },
  boxReturn2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
    width: "90%",
    padding: "0",
  },
  divBoxReturn2: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignContent: "flex-end",
  },
  gridTypography: {
    marginTop: "20px",
  },
  gridUbicacionIglesia: {
    marginLeft: "1px",
  },
  gridLugarNacimiento: {
    marginLeft: "1px",
  },
  typography7: {
    fontWeight: 500,
    color: "#31876C",
    marginTop: "15px",
  },
};

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
