import { TextField, Paper, Typography, Box, Grid } from "@mui/material";
import { styles } from "./CivilRegistrationFormStyles";
import {
  CivilRegistracionEnum,
  CivilRegistrationEnum,
  DATE,
  FECHA,
  SacramentoData,
  SacramentoDatos,
  Sizes,
} from "../../../enums/components/componentsEnum";

const CivilRegistrationForm = ({ title, formData, onChange, errors }: any) => {
  const handleFormChange = (key: any, value: string) => {
    onChange(key, value);
  };

  return (
    <Box>
      <Paper sx={styles.paper}>
        <Typography sx={styles.typography}>
          {title?.toUpperCase() ?? ""}
        </Typography>
        <Grid container spacing={2} sx={styles.gridItem}>
          <Grid item xs={6}>
            <TextField
              id={CivilRegistrationEnum.OFFICE}
              label={CivilRegistracionEnum.OFICINA}
              value={formData.office ?? ""}
              onChange={(e) =>
                handleFormChange(CivilRegistrationEnum.OFFICE, e.target.value)
              }
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.office}
              helperText={errors.office}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={CivilRegistrationEnum.CERTIFICATE}
              label={CivilRegistrationEnum.ACTA}
              value={formData.certificate ?? ""}
              onChange={(e) =>
                handleFormChange(
                  CivilRegistrationEnum.CERTIFICATE,
                  e.target.value,
                )
              }
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.certificate}
              helperText={errors.certificate}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={SacramentoData.PAGE}
              label={SacramentoDatos.PAGINA}
              value={formData.page ?? ""}
              onChange={(e) =>
                handleFormChange(SacramentoData.PAGE, e.target.value)
              }
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.page}
              helperText={errors.page}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={CivilRegistrationEnum.SECTION}
              label={CivilRegistracionEnum.SECCION}
              value={formData.section ?? ""}
              onChange={(e) =>
                handleFormChange(CivilRegistrationEnum.SECTION, e.target.value)
              }
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.section}
              helperText={errors.section}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={SacramentoData.BOOK}
              label={SacramentoDatos.LIBRO}
              type={SacramentoData.NUMBER}
              value={formData.book ?? ""}
              onChange={(e) =>
                handleFormChange(SacramentoData.BOOK, e.target.value)
              }
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              error={!!errors.book}
              helperText={errors.book}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={DATE}
              label={FECHA}
              type={DATE}
              value={formData.date ?? ""}
              onChange={(e) => handleFormChange(DATE, e.target.value)}
              margin={Sizes.NORMAL}
              size={Sizes.SMALL}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.date}
              helperText={errors.date}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CivilRegistrationForm;
