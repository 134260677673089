import { CSSProperties } from "react";

export const stylesListadoFeriado: Record<string, CSSProperties> = {
  box1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2%",
  },
  box2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
    width: "90%",
    padding: "0",
    overflow: "auto",
  },
  grid: {
    paddingTop: "2%",
  },
  button: {
    backgroundColor: "#3A8DBE",
  },
  gridRenderFilters: {
    boxShadow: "0px 0px 5px .1px #3176B0",
    borderRadius: "15px",
    margin: "0.5%",
    padding: "1%",
    gap: "1em",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridButtonRender: {
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
    color: "black",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
  },
};
