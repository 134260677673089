import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "@mui/material";

const NavLinkAdministratorGroups = ({
  to,
  label,
  isActiveStyle,
  defaultStyle,
}: any) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <RouterLink
      to={to}
      sx={isActive ? { ...defaultStyle, ...isActiveStyle } : defaultStyle}
      component={Link}
    >
      {label}
    </RouterLink>
  );
};

export default NavLinkAdministratorGroups;
