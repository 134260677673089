import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { getValidationColor } from "../../utils/autocomplete/autoCompleteUtils";
import { Sizes } from "../../enums/components/componentsEnum";
import { useEffect, useState } from "react";
import { LoadState } from "../../enums/LoadStateEnum";
import { useLocation } from "react-router-dom";
import {
  ArquidiocesisSelectProps,
  Option,
} from "../../interfaces/components/interfacesAutoComplete";

const ArquidiocesisSelect = ({
  id,
  label,
  options,
  disabled,
  onChange,
  size,
  loadState,
  errors,
  value,
}: ArquidiocesisSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const isValueInOptions = options
    ? options.some((option: any) => option.id === selectedOption?.id)
    : false;
  const location = useLocation();
  const eventId = location.state?.eventId;

  useEffect(() => {
    if (eventId) {
      loadState.current = LoadState.EDITING;
    }
  }, [loadState]);

  useEffect(() => {
    if (value && value !== selectedOption) {
      setSelectedOption(value);
    }
    onChange(id, value);

    getValidationColor(errors, errors?.[id], value, loadState?.current);
  }, [value]);

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    const newValue =
      options.find((option: any) => option.id === event.target.value) || null;
    setSelectedOption(newValue);
    onChange(id, newValue);
  };

  return (
    <FormControl
      variant="outlined"
      size={size}
      margin={Sizes.NORMAL}
      fullWidth
      error={errors?.[id]}
      style={{
        border: getValidationColor(
          errors,
          errors?.[id],
          value,
          loadState?.current,
        ),
        borderRadius: "4px",
      }}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        id={id}
        value={isValueInOptions ? selectedOption?.id.toString() : ""}
        onChange={handleOptionChange}
        disabled={disabled}
        label={label}
      >
        {options &&
          options.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
      </Select>
      {errors && <FormHelperText>{errors}</FormHelperText>}
    </FormControl>
  );
};

export default ArquidiocesisSelect;
