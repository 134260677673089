import { useState, useRef, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import StepperComponent from "../../components/stepper";
import { defaultErrorsData, defaultPadrinoData } from "../bautismo/utils";
import ChildForm from "../../components/forms/childForm";
import TurnoStep from "../../components/stepers/TurnoStep";
import SchedulerUtil from "../../utils/SchedulerUtil";
import { SacramentoEnum } from "../../enums/SacramentoEnum";
import GodParentsStep from "../../components/stepers/GodParentsStep";
import eventosService from "../../services/eventos";
import { initialFormState, STEPS_CONFIRMACION } from "./utils";
import {
  validateFirstStep,
  validateSecondStep,
  validateThirdStep,
} from "./validation";
import sacramentosService from "../../services/sacramentos";
import AlertModal from "../../components/forms/alertModal";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import { stylesConfirmacionPage } from "./confirmacionPageStyles";
import { RolSacramentoEnum } from "../../enums/RolSacramentoEnum";
import { getTimeZone } from "../../utils/datesUtils/dateUtils";

const ConfirmationPage = () => {
  const [alertType, setAlertType] = useState<"success" | "error" | null>(null);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>(initialFormState);
  const [errors, setErrors] = useState<any>(defaultErrorsData);
  const [godFatherForm, setGodFatherForm] = useState<any>([
    { ...defaultPadrinoData },
    { ...defaultPadrinoData },
  ]);
  const dateSelected = useRef(null);
  const location = useLocation();
  const eventId = location.state?.eventId;
  const timeZone = getTimeZone();

  useEffect(() => {
    if (eventId) {
      setActiveStep(1);
      //TODO we need change this to context
      ObservableService.notifyListeners(ObservableEvents.SACRAMENTO, eventId);

      eventosService
        .getRolsSacramentosByEventId(eventId)
        .then((response: any) => {
          response.forEach((assitent: any) => {
            if (assitent.rol == RolSacramentoEnum.owner) {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                state: { validate: true },
                child: {
                  ...assitent.person,
                  street: assitent.person?.address?.street,
                  number: assitent.person?.address?.number,
                  document_type: assitent?.person.document_type,
                  nationality: assitent.person?.nationality,
                  birthProvince: assitent.person?.locality?.part?.province,
                  birthPart: assitent.person?.locality?.part,
                  birthLocality: assitent.person?.locality,
                  country: {
                    ...assitent.person.address?.locality?.part?.province
                      ?.country,
                  },
                  part: { ...assitent.person.address?.locality?.part },
                  province: {
                    ...assitent.person.address?.locality?.part?.province,
                  },
                  locality: { ...assitent.person.address?.locality },
                  role: RolSacramentoEnum.owner,
                  checkMother: assitent.person?.checkMother,
                  checkFather: assitent.person?.checkFather,
                },
              }));
            }
          });
          let gofathers = response
            .filter(
              (assitent: any) =>
                assitent.rol == "godfather" || assitent.rol == "godmother",
            )
            .map((assitent: any) => {
              return {
                ...assitent.person,
                street: assitent.person.address?.street,
                number: assitent.person.address?.number,
                document_type: assitent.person?.document_type,
                nationality: assitent.person?.nationality,
                birthProvince: assitent.person?.locality?.part?.province,
                birthPart: assitent.person?.locality?.part,
                birthLocality: assitent.person?.locality,
                country: {
                  ...assitent.person.address?.locality?.part?.province?.country,
                },
                locality: { ...assitent.person.address?.locality },
                part: { ...assitent.person.address?.locality?.part },
                province: {
                  ...assitent.person.address?.locality?.part?.province,
                },
                role: "godfather",
              };
            });
          setGodFatherForm([...gofathers]);
        });
    }
  }, [eventId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepComplete = async () => {
    const noErrors: any = validateStep(activeStep);

    if (noErrors) {
      setErrors(defaultErrorsData);
      if (activeStep === tabsData.length - 1) {
        try {
          await tabsData[activeStep].action!();
          await handleSubmitForm();
        } catch (error) {}
      } else {
        if (!!tabsData[activeStep].action) {
          try {
            await tabsData[activeStep].action!();
            handleNext();
          } catch (error) {}
        }
      }
    }
  };

  const validateStep = (stepIndex: number) => {
    let currentStepErrors: any = {};

    switch (stepIndex) {
      case 0:
        currentStepErrors = validateFirstStep(dateSelected, "la confimacion");
        break;
      case 1:
        currentStepErrors = validateSecondStep(formData);
        break;
      case 2:
        currentStepErrors = validateThirdStep(godFatherForm);
        break;
      default:
        currentStepErrors = {};
    }

    if (currentStepErrors.hasError) {
      setErrors({ ...errors, ...currentStepErrors });
      return false;
    } else {
      return true;
    }
  };

  const validateIndiviualStep = (currentStepErrors: any) => {
    setErrors({ ...errors, ...currentStepErrors });
  };

  const handleFormChange = (key: any, value: string) => {
    const [parentKey, childKey] = key.split(".") as any;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [parentKey]: {
        ...prevFormData[parentKey],
        [childKey]: value,
      },
    }));
  };

  const handleAddPadrino = () => {
    setGodFatherForm([...godFatherForm, defaultPadrinoData]);
  };

  const handleRemovePadrino = (index: number) => {
    if (godFatherForm.length > 2) {
      setGodFatherForm((prevData: any) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
    } else {
    }
  };

  const handlePadrinoChange = (index: number, key: any, value: string) => {
    setGodFatherForm((prevData: any) => {
      const newData = [...prevData];
      let keys = key.split(".");
      if (keys.length > 1) {
        newData[index] = {
          ...newData[index],
          [keys[0]]: {
            ...newData[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        newData[index] = { ...newData[index], [key]: value };
      }
      return newData;
    });
  };
  const tabsData: any[] = [
    {
      label: STEPS_CONFIRMACION[0],
      component: (action: any) => (
        <TurnoStep
          handleStepComplete={handleStepComplete}
          type={SacramentoEnum.Confirmacion}
          formData={formData}
          dateSelected={dateSelected}
          errors={errors}
        />
      ),
      action: () => {
        SchedulerUtil.handleAction(
          dateSelected,
          SacramentoEnum.Confirmacion,
          timeZone,
        );
      },
    },
    {
      label: STEPS_CONFIRMACION[1],
      component: (action: any) => {
        return (
          <Grid item container justifyContent={"center"}>
            <Grid item xs={4}>
              <ChildForm
                title={"Confirmado/a"}
                formData={formData.child}
                role={RolSacramentoEnum.owner}
                onChange={(key: any, value: any) =>
                  handleFormChange(`child.${key}`, value)
                }
                errors={errors.child}
                setFormData={null as any}
              />
            </Grid>
          </Grid>
        );
      },
      action: async () => {
        await sacramentosService.addPeoples([formData?.child] as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos de la familia correctamente",
        });
      },
    },
    {
      label: STEPS_CONFIRMACION[2],
      component: (action: any) => {
        return (
          <GodParentsStep
            godFatherForm={godFatherForm}
            handleAddPadrino={handleAddPadrino}
            handleFormChange={handleFormChange}
            handlePadrinoChange={handlePadrinoChange}
            handleRemovePadrino={handleRemovePadrino}
            errors={errors}
            enableBautismoData={false}
            formData={formData}
            showRole={true}
          />
        );
      },
      action: () => {
        sacramentosService.addPeoples(godFatherForm as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos de los padrinos correctamente",
        });
      },
    },
  ];

  const handleSubmitForm = async () => {
    try {
      //TODO we need validate all dato before to validate the event
      /* sacramentosService.updateEventState('completed'); */
      eventosService.sendEmail(sacramentosService.getEventId());

      setAlertType("success");
      setIsModalOpen(true);
    } catch (error) {
      setAlertType("error");
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/panel");
  };

  return (
    <Box sx={stylesConfirmacionPage.boxGeneral}>
      <Box sx={stylesConfirmacionPage.container}>
        <Box sx={stylesConfirmacionPage.innerBox}>
          <Typography sx={stylesConfirmacionPage.typography}>
            Generacion de turno para la Confirmacion
          </Typography>
        </Box>
        <AlertModal
          onClose={handleCloseModal}
          open={isModalOpen}
          type={alertType}
        />
        <StepperComponent
          stepsData={tabsData}
          showBackButton={activeStep > 0}
          showNextButton={activeStep > 0}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepComplete={handleStepComplete}
        />
      </Box>
    </Box>
  );
};

export default ConfirmationPage;
