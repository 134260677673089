import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const StylesEnabledUserForUpload: Record<string, CSSProperties> = {
  boxGeneral: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1em",
    flexDirection: "column",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    padding: "0",
  },
  innerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0px",
  },
  typography0: {
    color: theme.font.primary,
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "1em",
  },
  box0: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    color: theme.font.primary,
    flexDirection: "column",
  },
  grid: {
    padding: "25px",
  },
  typography: {
    fontSize: "18px",
    textAlign: "center",
    color: "black",
    fontWeight: 600,
    marginBottom: "30px",
  },
  typography1: {
    margin: "10px 0px 0px 10px",
    fontSize: "12px",
  },
  typography2: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: 600,
    marginBottom: "30px",
    color: "black",
  },
  box1: {
    display: "flex",
    justifyContent: "flex-center",
    textAlign: "left",
    color: theme.font.primary,
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    width: "80%",
    marginLeft: "10%",
  },
  staticDatePicker: {
    backgroundColor: "#f5f5f5",
    borderRadius: "20px",
  },
  gridButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: "2%",
    marginBottom: "4%",
    width: "50%",
  },
  buttonSave: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  formRadioGroup: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    marginLeft: "5em",
  },
  formControlSacerdote: {
    width: "30%",
  },
  imputsSectorSacerdote: {
    marginTop: "-4px",
    lineHeight: "1",
  },
  typographyTipo: {
    color: "#264B99",
    marginBottom: "1em",
    fontSize: "1.1em",
  },
  selectTipo: {
    borderRadius: "5px",
  },
  typographyGroup: {
    color: "#264B99",
    marginTop: "1em",
    fontSize: "1.1em",
    marginBottom: "1em",
  },
};

export const stylesScheduleOnEnabledUSer = (
  index: any,
  timeSlot: any,
  selectedRange: any,
  clicked: boolean = false,
): { div: CSSProperties } => ({
  div: {
    display: "flex",
    width: "100%",
    alignSelf: "center",
    marginTop: "2%",
    backgroundColor: !clicked ? "#3A8DBE" : "#add8e6",
    paddingLeft: "4%",
    paddingRight: "4%",
    borderRadius: "10px",
    color: "white",
    cursor: "pointer",
    opacity: timeSlot.state == "RESERVED" ? 0.3 : 1,
    justifyContent: "center",
    flexDirection: "row",
  },
});
