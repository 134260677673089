import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  ActividadParroquialTextos,
  AltaParticipanteTextos,
} from "../../../enums/pages/CreateActividadParroquialEnum";
import { Sizes } from "../../../enums/components/componentsEnum";
import { PeopleActividadParroquialStyles } from "./PeopleActividadParroquialStyles";
import LocationForm from "../../../components/forms/genericLocationForm/LocationForm";
import { CargaEscuelaProps } from "../InterfacesActividadParroquial";

const CargaEscuela: React.FC<CargaEscuelaProps> = ({
  participante,
  handleFormChange,
  disabledSchool,
  addressIglesia,
  onChangeAddressIglesia,
  isFetch,
  schoolLevel,
  levels,
  onChangeSchoolLevel,
  loadState,
  colegios,
  setParticipante,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          style={PeopleActividadParroquialStyles.typographyCarga}
        >
          {ActividadParroquialTextos.CargaDeParticipante}
        </Typography>
      </Grid>

      {/* Formulario de localización */}
      <Grid
        container
        columnSpacing={2}
        style={PeopleActividadParroquialStyles.gridContainerParticipanteForm}
      >
        <LocationForm
          formData={addressIglesia}
          onChange={onChangeAddressIglesia}
          nacimiento={true}
          isFetch={isFetch}
          loadState={loadState}
          isInActivityParroquial={true}
          disabled={disabledSchool}
        />

        {/* SchoolLevel */}
        <Grid item xs={6}>
          <Typography style={PeopleActividadParroquialStyles.typographySchool}>
            {AltaParticipanteTextos.NIVEL_ESCOLAR}
          </Typography>
          <FormControl fullWidth variant="outlined">
            <Select
              value={schoolLevel}
              onChange={(e) => {
                onChangeSchoolLevel(e.target.value);
              }}
              name="schoolLevel"
              size={Sizes.SMALL}
              required
              disabled={!participante.school.localityId}
            >
              <MenuItem key="todos" value="0">
                Todos
              </MenuItem>
              {levels &&
                levels.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.cod === 6
                      ? option.description
                      : `${option.description} - cod: ${option.cod}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Escuela */}
        <Grid item xs={6}>
          <Typography style={PeopleActividadParroquialStyles.typographySchool}>
            {AltaParticipanteTextos.ESCUELA}
          </Typography>
          <FormControl fullWidth variant="outlined">
            <Select
              value={participante.school.name}
              onChange={(e) => {
                const selectedSchool = colegios.find(
                  (option) => option.name === e.target.value,
                );
                const cue = selectedSchool ? selectedSchool.cue : "";
                setParticipante((prev: any) => ({
                  ...prev,
                  school: {
                    ...prev.school,
                    name: e.target.value,
                    schoolId: selectedSchool?.id ?? null,
                    cue: cue,
                  },
                }));
              }}
              name="school"
              size={Sizes.SMALL}
              required
              disabled={disabledSchool}
            >
              {colegios &&
                colegios.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Año cursando y CUE */}
        <Grid item xs={6}>
          <Typography style={PeopleActividadParroquialStyles.typographySchool1}>
            {AltaParticipanteTextos.ANIO_CURSANDO}
          </Typography>
          <FormControl fullWidth>
            <TextField
              value={participante.school.yearStudying}
              onChange={(e) =>
                handleFormChange("school.yearStudying", e.target.value)
              }
              name="yearStudying"
              size={Sizes.SMALL}
              disabled={disabledSchool}
            ></TextField>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <Typography style={PeopleActividadParroquialStyles.typographySchool1}>
            {AltaParticipanteTextos.CUE}
          </Typography>
          <FormControl fullWidth>
            <TextField
              value={participante.school.cue}
              name="cue"
              size={Sizes.SMALL}
              disabled={true}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CargaEscuela;
