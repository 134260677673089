import { useState, useEffect, useRef } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  defaultErrorsData,
  defaultPadrinoData,
  initialFormState,
} from "./utils";
import StepperComponent from "../../components/stepper";
import {
  validateFirstStep,
  validateSecondStep,
  validateThirdStep,
  validateFourthStep,
  validateFourtStep,
  validateFiveStep,
} from "./validation";
import sacramentosService from "../../services/sacramentos";
import SchedulerUtil from "../../utils/SchedulerUtil";
import { SacramentoEnum } from "../../enums/SacramentoEnum";
import AlertModal from "../../components/forms/alertModal";
import TurnoStep from "../../components/stepers/TurnoStep";
import FamilyStep from "../../components/stepers/FamilyStep";
import GodParentsStep from "../../components/stepers/GodParentsStep";
import eventosService from "../../services/eventos";
import ObservableService, {
  ObservableEvents,
} from "../../services/ObservableService";
import { stylesCasamietnoPage } from "./casamientoPageStyles";
import ReligionSelector from "../../components/autocomplete/religion/ReligionSelector";
import BaptismForm from "../../components/forms/baptismForm";
import { getTimeZone } from "../../utils/datesUtils/dateUtils";

const STEPS = [
  "Turnos",
  "Datos del Esposo",
  "Datos de la Esposa",
  "Padrinos",
  "Testigos",
];

const CasamientoPage = () => {
  const [alertType, setAlertType] = useState<"success" | "error" | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<any>(initialFormState);
  const activeStepRef = useRef(0);

  const [godFatherForm, setGodFatherForm] = useState<any>([
    { ...defaultPadrinoData },
    { ...defaultPadrinoData },
  ]);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [errors, setErrors] = useState<any>(defaultErrorsData);
  const dateSelected = useRef({
    day: null,
    start: null,
    end: null,
    turnoId: null,
    specificationId: null,
    limit: null,
    count: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location.state?.eventId;
  const timeZone = getTimeZone();

  useEffect(() => {
    activeStepRef.current = activeStep;
  }, [activeStep]);

  const createFormData = (assitent: any, role: string) => {
    let mapping = {
      ...assitent,
      street: assitent?.address?.street,
      number: assitent?.address?.number,
      document_type: assitent?.document_type,
      nationality: { ...assitent?.nationality },
      birthProvince: { ...assitent?.locality?.part?.province },
      birthPart: { ...assitent?.locality?.part },
      birthLocality: { ...assitent?.locality },
      country: { ...assitent?.address?.locality?.part?.province?.country },
      locality: { ...assitent?.address?.locality },
      part: { ...assitent?.address?.locality?.part },
      province: { ...assitent?.address?.locality?.part?.province },
      religion: assitent?.religion?.id,
      role: role,
    };

    assitent?.sacramentos.forEach((sacramento: any) => {
      if (
        !!sacramento.sacramentoShadow &&
        sacramento.sacramentoShadow.type == SacramentoEnum.Bautismo
      ) {
        mapping = {
          ...mapping,
          baptism: {
            ...sacramento.sacramentoShadow,
            country: {
              ...sacramento.sacramentoShadow?.iglesia?.locality?.part?.province
                ?.country,
            },
            locality: { ...sacramento.sacramentoShadow?.iglesia?.locality },
            part: { ...sacramento.sacramentoShadow?.iglesia?.locality?.part },
            province: {
              ...sacramento.sacramentoShadow?.iglesia?.locality?.part?.province,
            },
            iglesia: { ...sacramento.sacramentoShadow?.iglesia },
          },
        };
      }
    });

    return mapping;
  };

  useEffect(() => {
    if (eventId) {
      setActiveStep(1);

      ObservableService.notifyListeners(ObservableEvents.SACRAMENTO, eventId);
      eventosService
        .getRolsSacramentosByEventId(eventId)
        .then((response: any) => {
          response.forEach((assitent: any) => {
            if (assitent.rol == "husband") {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                state: { validate: true },
                husband: {
                  ...createFormData(assitent?.person, "husband"),
                },
              }));
            }
            if (assitent.rol == "wife") {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                state: { validate: true },
                wife: {
                  ...createFormData(assitent?.person, "wife"),
                },
              }));
            }
          });

          let gofathers = response
            .filter(
              (assitent: any) =>
                assitent.rol == "godfather" || assitent.rol == "godmother",
            )
            .map((assitent: any) => {
              let mapping = {
                ...assitent.person,
                street: assitent.person.address?.street,
                number: assitent.person.address?.number,
                document_type: assitent.person?.document_type,
                nationality: { ...assitent.person?.nationality },
                birthProvince: { ...assitent.person?.locality?.part?.province },
                birthPart: { ...assitent.person?.locality?.part },
                birthLocality: { ...assitent.person?.locality },
                country: {
                  ...assitent.person.address?.locality?.part?.province?.country,
                },
                locality: { ...assitent.person.address?.locality },
                part: { ...assitent.person.address?.locality?.part },
                province: {
                  ...assitent.person.address?.locality?.part?.province,
                },
                role: assitent.rol,
              };

              assitent.person.sacramentos.forEach((sacramento: any) => {
                if (
                  !!sacramento.sacramentoShadow &&
                  sacramento.sacramentoShadow.type == SacramentoEnum.Bautismo
                ) {
                  mapping = {
                    ...mapping,
                    baptism: {
                      ...sacramento.sacramentoShadow,
                      country: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality?.part
                          ?.province?.country,
                      },
                      locality: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality,
                      },
                      part: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality?.part,
                      },
                      province: {
                        ...sacramento.sacramentoShadow?.iglesia?.locality?.part
                          ?.province,
                      },
                      iglesia: { ...sacramento.sacramentoShadow?.iglesia },
                    },
                  };
                }
              });

              return mapping;
            });
          setGodFatherForm([...gofathers]);

          let witness = response.filter(
            (assitent: any) => assitent.rol == "witness",
          );
          if (witness.length > 0 && witness.length == 2) {
            setFormData((prevFormData: any) => ({
              ...prevFormData,
              state: { validate: true },
              witnessOne: {
                ...createFormData(witness[0]?.person, "witness"),
              },
              witnessTwo: {
                ...createFormData(witness[1]?.person, "witness"),
              },
            }));
          } else {
            setFormData((prevFormData: any) => ({
              ...prevFormData,
              state: { validate: true },
              witnessOne: {
                ...createFormData(witness[0]?.person, "witness"),
              },
            }));
          }
        });
    }
  }, [eventId]);

  useEffect(() => {
    if (formData?.state?.validate) {
      validateStep(1);
    }
  }, [formData]);

  const handleSubmitForm = async () => {
    try {
      //TODO we need validate all dato before to validate the event
      /* sacramentosService.updateEventState('completed'); */
      eventosService.sendEmail(sacramentosService.getEventId());

      setAlertType("success");
      setIsModalOpen(true);
    } catch (error) {
      setAlertType("error");
      setIsModalOpen(true);
    }
  };

  const validateIndiviualStep = (currentStepErrors: any) => {
    setErrors({ ...errors, ...currentStepErrors });
  };

  const tabsData: any = [
    {
      label: STEPS[0],
      component: (action: any) => (
        <TurnoStep
          type={SacramentoEnum.Casamiento}
          formData={formData}
          handleStepComplete={handleStepComplete}
          dateSelected={dateSelected}
          errors={errors}
        />
      ),
      action: async () => {
        await SchedulerUtil.handleAction(
          dateSelected,
          SacramentoEnum.Casamiento,
          timeZone,
        );
      },
    },
    {
      label: STEPS[1],
      component: () => (
        <FamilyStep
          title="Esposo"
          formData={formData}
          handleFormChange={handleFormChange}
          errors={errors}
          validateIndiviualStep={validateIndiviualStep}
          childs={[]}
          adults={[
            {
              accessor: "husband",
              label: "Esposo",
              role: "husband",
              statusCivil: true,
              extraData: () => {
                return (
                  <Grid item xs={4}>
                    <Paper
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "25px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#F8F8F8",
                        width: "100%",
                      }}
                    >
                      <ReligionSelector
                        value={formData["husband"]?.religion}
                        errors={!!errors["husband"]?.religion}
                        helperText={errors["husband"].religion}
                        onChange={(id: any, value: any) => {
                          handleFormChange("husband.religion", value?.id);
                          //@ts-ignore
                          handleFormChange(
                            "husband.enabledReligionForm",
                            //@ts-ignore
                            value?.religion == "Católica Apostólica Romana",
                          );
                        }}
                      />
                      {formData["husband"]?.enabledReligionForm && (
                        <BaptismForm
                          customStyle={{}}
                          title="Datos del Bautismo"
                          formData={formData["husband"]}
                          onChange={(key: any, value: any) =>
                            handleFormChange(`husband.baptism.${key}`, value)
                          }
                          errors={errors.baptism}
                          inGeneratedTurn={true}
                        />
                      )}
                    </Paper>
                  </Grid>
                );
              },
            },
          ]}
        />
      ),
      action: async () => {
        await sacramentosService.addPeoples([formData?.husband] as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos del esposo correctamente",
        });
      },
    },
    {
      label: STEPS[2],
      component: () => (
        <FamilyStep
          title="Esposa"
          formData={formData}
          handleFormChange={handleFormChange}
          errors={errors}
          validateIndiviualStep={validateIndiviualStep}
          childs={[]}
          adults={[
            {
              accessor: "wife",
              label: "Esposa",
              role: "wife",
              statusCivil: true,
              extraData: () => {
                return (
                  <Grid item xs={4}>
                    <Paper
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "25px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                        backgroundColor: "#F8F8F8",
                        width: "100%",
                      }}
                    >
                      <ReligionSelector
                        value={formData["wife"]?.religion}
                        errors={!!errors["wife"]?.religion}
                        helperText={errors["wife"].religion}
                        onChange={(id: any, value: any) => {
                          handleFormChange("wife.religion", value?.id);
                          //@ts-ignore
                          handleFormChange(
                            "wife.enabledReligionForm",
                            //@ts-ignore
                            value?.religion == "Católica Apostólica Romana",
                          );
                        }}
                      />
                      {formData["wife"]?.enabledReligionForm && (
                        <BaptismForm
                          customStyle={{}}
                          title="Datos del Bautismo"
                          formData={formData["wife"]}
                          onChange={(key: any, value: any) =>
                            handleFormChange(`wife.baptism.${key}`, value)
                          }
                          errors={errors.baptism}
                          inGeneratedTurn={true}
                        />
                      )}
                    </Paper>
                  </Grid>
                );
              },
            },
          ]}
        />
      ),
      action: async () => {
        await sacramentosService.addPeoples([formData?.wife] as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos de la esposa correctamente",
        });
      },
    },
    {
      label: STEPS[3],
      component: () => (
        <GodParentsStep
          godFatherForm={godFatherForm}
          handleAddPadrino={handleAddPadrino}
          handleFormChange={handleFormChange}
          handlePadrinoChange={handlePadrinoChange}
          handleRemovePadrino={handleRemovePadrino}
          errors={errors}
          enableBautismoData={true}
          formData={formData}
          showRole={true}
        />
      ),
      action: async () => {
        await sacramentosService.addPeoples(godFatherForm as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos de los padrinos correctamente",
        });
      },
    },
    {
      label: STEPS[4],
      component: () => (
        <FamilyStep
          title="Esposo"
          formData={formData}
          handleFormChange={handleFormChange}
          errors={errors}
          validateIndiviualStep={validateIndiviualStep}
          childs={[]}
          adults={[
            { accessor: "witnessOne", label: "Testigo", role: "witness" },
            { accessor: "witnessTwo", label: "Testigo", role: "witness" },
          ]}
        />
      ),
      action: async () => {
        await sacramentosService.addPeoples([
          formData?.witnessOne,
          formData?.witnessTwo,
        ] as any);
        ObservableService.notifyListeners(ObservableEvents.NOTIFICATION, {
          type: "success",
          message: "Se guardaron los datos del esposo correctamente",
        });
      },
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepComplete = async () => {
    const noErrors: any = validateStep(activeStep);

    if (noErrors) {
      setErrors(defaultErrorsData);
      if (activeStep === tabsData.length - 1) {
        try {
          await tabsData[activeStep].action!();
          await handleSubmitForm();
        } catch (error) {}
      } else {
        if (!!tabsData[activeStep].action) {
          try {
            await tabsData[activeStep].action!();
            handleNext();
          } catch (error) {}
        }
      }
    }
  };

  const validateStep = (stepIndex: number) => {
    let currentStepErrors: any = {};

    switch (stepIndex) {
      case 0:
        currentStepErrors = validateFirstStep(dateSelected, "el casamiento");
        break;
      case 1:
        currentStepErrors = validateSecondStep(formData);
        break;
      case 2:
        currentStepErrors = validateThirdStep(formData);
        break;
      case 3:
        currentStepErrors = validateFourtStep(godFatherForm);
        break;
      case 4:
        currentStepErrors = validateFiveStep(formData);
        break;
      default:
        currentStepErrors = {};
    }

    if (currentStepErrors.hasError) {
      setErrors({ ...errors, ...currentStepErrors });
      return false;
    } else {
      setErrors(defaultErrorsData);
      return true;
    }
  };

  const handleAddPadrino = () => {
    setGodFatherForm([...godFatherForm, { ...defaultPadrinoData }]);
  };

  const handleRemovePadrino = (index: number) => {
    if (godFatherForm.length > 2) {
      setGodFatherForm((prevData: any) => {
        const newData = [...prevData];
        newData.splice(index, 1);
        return newData;
      });
    } else {
    }
  };

  const handlePadrinoChange = (index: number, key: any, value: string) => {
    setGodFatherForm((prevData: any) => {
      const newData = [...prevData];
      let keys = key.split(".");
      if (keys.length > 1) {
        newData[index] = {
          ...newData[index],
          [keys[0]]: {
            ...newData[index][keys[0]],
            [keys[1]]: value,
          },
        };
      } else {
        newData[index] = { ...newData[index], [key]: value };
      }
      return newData;
    });
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/panel");
  };

  const handleFormChange = (key: any, value: string) => {
    if (key.split(".").length > 2) {
      const [parentKey, childKey, subChildKey] = key.split(".") as any;

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [parentKey]: {
          ...prevFormData[parentKey],
          [childKey]: {
            ...prevFormData[parentKey][childKey],
            [subChildKey]: value,
          },
        },
      }));
    } else {
      const [parentKey, childKey] = key.split(".") as any;

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [parentKey]: {
          ...prevFormData[parentKey],
          [childKey]: value,
        },
      }));
    }
  };

  return (
    <Box sx={stylesCasamietnoPage.boxGeneral}>
      <Box sx={stylesCasamietnoPage.container}>
        <Box sx={stylesCasamietnoPage.innerBox}>
          <Typography sx={stylesCasamietnoPage.typography}>
            Generacion de turno para casamiento
          </Typography>
        </Box>
        <AlertModal
          onClose={handleCloseModal}
          open={isModalOpen}
          type={alertType}
        />
        <StepperComponent
          stepsData={tabsData}
          showBackButton={activeStep > 0}
          showNextButton={activeStep > 0}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepComplete={handleStepComplete}
        />
      </Box>
    </Box>
  );
};

export default CasamientoPage;
