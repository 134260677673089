export const diasSemana = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

export enum SectorEnum {
  NOMBRE = "Nombre",
  NAMES = "names",
  USO = "Uso",
  USE = "use",
  DIA = "Dia",
  HORARIO_INICIAL = "Horario Inicial",
  HORARIO_FINAL = "Horario Final",
  ACCIONES = "Acciones",
  EDITAR_SECTOR = "Editar Sector",
  ACTUALIZAR_SECTOR = "Actualizar sector",
  SECTOR = "Sector ",
  ACTUALIZADO_CORRECTAMENTE = " actualizado correctamente.",
  ERROR_ACTUALIZAR_SECTOR = "No se pudo actualizar el sector. Error: ",
  SUCCESS = "success",
  ERROR = "error",
  TIME = "time",
  HORARIOINICIAL = "horarioInicial",
  HORARIOFINAL = "horarioFinal",
  SUBMIT = "submit",
  CONTAINED = "contained",
  PRIMARY = "primary",
}
