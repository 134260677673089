// utils.ts
export function updateFilters(filters: any, param: any, newValue: any) {
  let oldValueUpdated;
  let updatedFilters = [...filters];
  updatedFilters.forEach((filter, index) => {
    if (filter.param === param) {
      oldValueUpdated = index;
    }
  });
  if (oldValueUpdated != undefined) {
    updatedFilters[oldValueUpdated] = {
      param: param,
      value: newValue,
    };
  } else {
    updatedFilters = [...updatedFilters, { param: param, value: newValue }];
  }
  return updatedFilters;
}

export function initializeFilters(location: any) {
  return [
    {
      param: "nombre",
      value: new URLSearchParams(location.search).get("nombre") || "",
    },
    {
      param: "apellido",
      value: new URLSearchParams(location.search).get("apellido") || "",
    },
  ];
}

export function getSacerdoteShadowColumns() {
  return [
    { name: "Id", accessor: "id" },
    { name: "Nombre", accessor: "nombre" },
    { name: "Apellido", accessor: "apellido" },
  ];
}
