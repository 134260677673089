import { useEffect, useState } from "react";
import { locacionesService } from "../../../services/locaciones";
import { useQuery } from "react-query";
import { Grid } from "@mui/material";
import LocationSelect from "../../autocomplete/countries/LocationSelect";
import { LoadState } from "../../../enums/LoadStateEnum";
import {
  DOCUMENT,
  DataPersonal,
  DatosPersonales,
  Errors,
  IGLESIA,
  IGLESIA_CAPITALIZED,
  LOCACIONES_CAPITALIZED_OFF,
  Locaciones,
  Locations,
  Sizes,
} from "../../../enums/components/componentsEnum";
import { useLocation } from "react-router-dom";

const LocationForm = ({
  formData,
  onChange,
  errors,
  nacimiento,
  disabled = false,
  baptismForm = false,
  isFetch = false,
  loadState = { current: LoadState.DEFAULT },
  isInActivityParroquial = false,
}: any) => {
  const [provinces, setProvinces] = useState<any[]>([]);
  const [parts, setParts] = useState<any[]>([]);
  const [localities, setLocalities] = useState<any[]>([]);
  const [birthLocalities, setBirthLocalities] = useState<any[]>([]);
  const [birthProvinces, setBirthProvinces] = useState<any[]>([]);
  const [birthParts, setBirthParts] = useState<any[]>([]);
  const [baptismProvince, setBaptismProvince] = useState<any>(null);
  const [baptismPart, setBaptismPart] = useState<any>(null);
  const [baptismLocality, setBaptismLocality] = useState<any>(null);
  const [iglesias, setIglesias] = useState<any[]>([]);
  const location = useLocation();
  const eventId = location.state?.eventId;

  const {
    data: countries,
    isLoading: locacionesDataIsLoading,
    error: locacionesDataError,
  } = useQuery<any | null>([LOCACIONES_CAPITALIZED_OFF], () =>
    locacionesService.getCountries(),
  );

  useEffect(() => {
    if (eventId && baptismForm) {
      loadState.current = LoadState.EDITING;
    }
    if (isFetch) {
      loadState.current = LoadState.LOADED;
    }
  }, [formData]);

  const handleCountryChange = (key: any, value: any) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    onChange(key as any, value);

    locacionesService
      .findProvincesByCountryId(value.id)
      .then((response: any) => {
        if (nacimiento) {
          setBirthProvinces(response);
        } else if (baptismForm) {
          setBaptismProvince(response);
        } else {
          setProvinces(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PROVINCES, error);
      });
  };

  const handleProvinceChange = (key: any, value: any) => {
    if (!value) return;
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    onChange(key as any, value);
    locacionesService
      .findPartsByProvinceId(value.id)
      .then((response: any) => {
        if (nacimiento) {
          setBirthParts(response);
        } else if (baptismForm) {
          setBaptismPart(response);
        } else {
          setParts(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PARTS, error);
      });
  };

  const handlePartChange = (key: any, value: any) => {
    if (!value) return;
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT;
    }
    onChange(key as any, value);

    if (baptismForm) {
      locacionesService
        .findIglesiasAndLocalitiesByPartId(value?.id)
        .then((response: any) => {
          const iglesias = response.flatMap((locality: any) => {
            const localityName = locality.name;
            return locality.iglesias.map((iglesia: any) => {
              iglesia.name = `${iglesia.denomination} (${localityName})`;
              return iglesia;
            });
          });
          setIglesias(iglesias);

          if (nacimiento) {
            setBirthLocalities(response);
          } else if (baptismForm) {
            setBaptismLocality(response);
          } else {
            setLocalities(response);
          }
        })
        .catch((error: any) => {
          console.log(Errors.ERROR_GET_LOCALITIES, error);
        });
    } else {
      locacionesService
        .findLocalitiesByPartId(value?.id)
        .then((response: any) => {
          if (nacimiento) {
            setBirthLocalities(response);
          } else if (baptismForm) {
            setBaptismLocality(response);
          } else {
            setLocalities(response);
          }
        })
        .catch((error: any) => {
          console.log(Errors.ERROR_GET_LOCALITIES, error);
        });
    }
  };

  return (
    <>
      {/* Country */}
      <Grid item xs={6}>
        <LocationSelect
          id={nacimiento ? DataPersonal.NATIONALITY : Locations.COUNTRY}
          label={
            isInActivityParroquial
              ? Locaciones.PAIS
              : nacimiento
                ? DatosPersonales.LUGAR_NACIMIENTO
                : Locaciones.PAIS
          }
          options={countries ?? []}
          disabled={disabled}
          value={
            baptismForm
              ? formData.baptism?.country
              : nacimiento
                ? formData?.nationality
                : (formData?.country ?? null)
          }
          onChange={handleCountryChange}
          size={Sizes.SMALL}
          loadState={loadState}
          errors={baptismForm ? errors?.baptism : nacimiento ? errors : errors}
          helperText={
            baptismForm
              ? (errors?.baptism?.country ?? "")
              : nacimiento
                ? (errors?.nationality ?? "")
                : (errors?.country ?? "")
          }
        />
      </Grid>
      {/* Province */}
      <Grid item xs={6}>
        <LocationSelect
          id={nacimiento ? Locations.BIRTH_PROVINCE : Locations.PROVINCE}
          label={
            isInActivityParroquial
              ? Locaciones.PROVINCIA
              : nacimiento
                ? Locaciones.PROVINCIA_NACIMIENTO
                : Locaciones.PROVINCIA
          }
          options={
            baptismForm
              ? (baptismProvince ?? [])
              : nacimiento
                ? (birthProvinces ?? [])
                : (provinces ?? [])
          }
          disabled={disabled}
          value={
            baptismForm
              ? formData.baptism?.province
              : nacimiento
                ? formData?.birthProvince
                : (formData?.province ?? null)
          }
          onChange={handleProvinceChange}
          size={Sizes.SMALL}
          loadState={loadState}
          errors={baptismForm ? errors?.baptism : nacimiento ? errors : errors}
          helperText={
            baptismForm
              ? (errors?.baptism?.province ?? "")
              : nacimiento
                ? (errors?.birthProvince ?? "")
                : (errors?.province ?? "")
          }
        />
      </Grid>
      {/* Part */}
      <Grid item xs={6}>
        <LocationSelect
          id={nacimiento ? Locations.BIRTH_PART : Locations.PART}
          label={
            isInActivityParroquial
              ? Locaciones.PARTIDO
              : nacimiento
                ? Locaciones.PARTIDO_NACIMIENTO
                : Locaciones.PARTIDO
          }
          options={
            baptismForm
              ? (baptismPart ?? [])
              : nacimiento
                ? (birthParts ?? [])
                : (parts ?? [])
          }
          disabled={disabled}
          value={
            baptismForm
              ? formData.baptism?.part
              : nacimiento
                ? formData?.birthPart
                : (formData?.part ?? null)
          }
          onChange={handlePartChange}
          size={Sizes.SMALL}
          loadState={loadState}
          errors={baptismForm ? errors?.baptism : nacimiento ? errors : errors}
          helperText={
            baptismForm
              ? (errors?.baptism?.part ?? "")
              : nacimiento
                ? (errors?.birthPart ?? "")
                : (errors?.part ?? "")
          }
        />
      </Grid>
      {/* Locality */}
      {!baptismForm && (
        <Grid item xs={6}>
          <LocationSelect
            id={nacimiento ? Locations.BIRTH_LOCALITY : Locations.LOCALITY}
            label={
              isInActivityParroquial
                ? Locaciones.LOCALIDAD
                : nacimiento
                  ? Locaciones.LOCALIDAD_NACIMIENTO
                  : Locaciones.LOCALIDAD
            }
            options={
              baptismForm
                ? (baptismLocality ?? [])
                : nacimiento
                  ? (birthLocalities ?? [])
                  : (localities ?? [])
            }
            disabled={disabled}
            value={
              baptismForm
                ? formData.baptism?.locality
                : nacimiento
                  ? formData?.birthLocality
                  : (formData?.locality ?? null)
            }
            onChange={onChange}
            size={Sizes.SMALL}
            loadState={loadState}
            errors={
              baptismForm ? errors?.baptism : nacimiento ? errors : errors
            }
            helperText={
              baptismForm
                ? (errors?.baptism?.locality ?? "")
                : nacimiento
                  ? (errors?.birthLocality ?? "")
                  : (errors?.locality ?? "")
            }
          />
        </Grid>
      )}
      {baptismForm && (
        <Grid item xs={6}>
          <LocationSelect
            id={IGLESIA}
            label={IGLESIA_CAPITALIZED}
            options={iglesias ?? []}
            value={formData?.baptism?.iglesia ?? null}
            disabled={disabled}
            loadState={loadState}
            onChange={(key: any, value: any) => {
              onChange(key, value);
            }}
            size={Sizes.SMALL}
            errors={errors}
            helperText={errors?.part ?? ""}
          />
        </Grid>
      )}
    </>
  );
};

export default LocationForm;
